import React, { Component } from "react";
import "./UserCommonBackground.css";
import { url } from "../../constants";
import LandingScreen from "../LandingScreen/LandingScreen";
import CreateAccountScreen from "../CreateAccountScreen/CreateAccountScreen";
import TermsScreen from "../TermsScreen/TermsScreen";
import SelectBuyerPlatform from "../SelectBuyerPlatform/SelectBuyerPlatform";


export default class UserCommonBackground extends Component {

    render() {
        let extraData = 'a'

        let id = null;
        let name = "";
        let phone = null;
        let email = null;
        let password = null;

        if (window.localStorage.getItem("_id")) {
            id = window.localStorage.getItem("_id");
        }

        if (window.localStorage.getItem("_name")) {
            name = window.localStorage.getItem("_name");
        }
        if (window.localStorage.getItem("_phone")) {
            phone = window.localStorage.getItem("_phone");
        }
        if (window.localStorage.getItem("_email")) {
            email = window.localStorage.getItem("_email");
        }
        if (window.localStorage.getItem("_password")) {
            password = window.localStorage.getItem("_password");
        }

        //console.log("user details in common page: ", id, " ", name, " ", phone, " ", email, " ", password)

        const logo = require("../../assests/logo-white.png");
        const Approval = require("../../assests/approval.png");
        const Payouts = require("../../assests/payouts.png");
        const Solution = require("../../assests/solution.png");
        const Secured = require("../../assests/secured.png");
        return (

            <div className="landRoot">
                <div class="login-wrap">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="login-inner">
                                <div className="logog">
                                    <a href="/">
                                        <img src={logo} style={{ height: 80, width: 270 }} />
                                    </a>
                                </div>
                                <div className="feature1">
                                    <div className="square"><img src={Approval} style={{ width: 60 }} /></div>
                                    <div className="inColumn">
                                        <p className="bigLine">Fast Approval</p>
                                        <p className="smallLine">Integrate your online accounts and get approved in a day</p>
                                    </div>
                                </div>
                                <div className="feature1">
                                    <div className="square"><img src={Payouts} style={{ width: 52 }} /></div>
                                    <div className="inColumn">
                                        <p className="bigLine">Accelerate Payouts</p>
                                        <p className="smallLine">Accelerate receivables through a click of a button and receive capital in 24 hours</p>
                                    </div>
                                </div>
                                <div className="feature1">
                                    <div className="square"><img src={Solution} style={{ width: 54 }} /></div>
                                    <div className="inColumn">
                                        <p className="bigLine">Integrated Solution</p>
                                        <p className="smallLine">Aggregate your receivables through one platform and no invoice uploads required</p>
                                    </div>
                                </div>
                                <div className="feature1">
                                    <div className="square"><img src={Secured} style={{ width: 54 }} /></div>
                                    <div className="inColumn">
                                        <p className="bigLine">Trusted and Secured</p>
                                        <p className="smallLine">End to end encrypted service and secured through direct transfers</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div className="form">
                                {
                                    // (window.location.pathname).split('/')[1] == 'user' ||
                                    // window.location.pathname == `/` ? 
                                    // <LandingScreen />
                                    // :
                                    (window.location.pathname).split('/')[2] == 'createAccount' ?
                                        <CreateAccountScreen />
                                        :
                                        (window.location.pathname).split('/')[2] == "tnc" && id != null ?
                                            <TermsScreen id={id} name={name} email={email} phone={phone} pass={password} />
                                            :
                                            (window.location.pathname).split('/')[2] == 'SelectBuyerPlatform' && id != null ?
                                                <SelectBuyerPlatform userId={id} />
                                                : <LandingScreen />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}