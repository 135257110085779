import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import "./UserProfile.css";
import Layout from "../../HomeLayout/Layout";
import _ from 'lodash'
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner"
import "react-datepicker/dist/react-datepicker.css";
import { UserProfileActions } from "../../actions";
import moment from "moment";
import PdfViewer from "../../components/Modals/PdfViewer/PdfViewer";





class UserProfile extends Component {
  id = window.localStorage.getItem("_id");
  constructor(props) {
    super(props);
    this.state = {
      "id": window.localStorage.getItem("_id"),
      "name": window.localStorage.getItem("_name"),
      "viewDocumentLoading": false,
      "openModal": false,
      "PdfViewerData": "",
      "fileType": ""
    }
  }


  componentDidMount() {
    const id = window.localStorage.getItem("_id");
    this.setState({
      "viewDocumentLoading": true
    })
    this.props.getUserProfile(id).then(() => {
      this.props.getUserDocuments(id).then(() => {
        this.setState({
          "viewDocumentLoading": false
        })
      });
    });
  }





  displayFile = (file) => {
    console.log("Mohan");
    this.setState({
      "PdfViewerData": file,
      "openModal": true
    })
  }

  closeModal = () => {
    this.setState({
      "openModal": false
    })
  }

  convertBase64ToBlobUrl = (data, fileType) => {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }

    var blobStore = new Blob([bytes], {
      type: "application/" + fileType
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    return data = window.URL.createObjectURL(blobStore);

  }

  displayFile = (data) => {
    console.log("Data9", data);
    console.log("this.props.userDocument", this.props.userDocument);
    if (data != "") {

      var data3 = this.props.userDocument.filter((index) => {
        return index.documentName === data
      })
      if (data3.length > 0) {
        return this.convertBase64ToBlobUrl(data3[0].base64Doc, data3[0].docType)
      }
    }
  }




  individualInvestor = () => {
    console.log("this.state", this.state)
    return (
      <div className="col-lg-12">
        <div className="w-auto">
          <div className="rounded_lg pt-0 p-4 px-0">
            <h3 className="rounded pt-2 mb-0 listhead">Individual</h3>
            <form className="userProfile">
              <div className="col-lg-8 boxNot">
                <div className="id_date_box themeBG h-auto pb-3 mt-0">
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="form-group">
                        <label for="bank_branch_name">ID</label>
                        <p className="m-0 fs-5">{this.props.userProfile.uniqueId}</p>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group">
                        <label for="bank_branch_name">STATUS</label>
                        <p className="m-0 fs-5">{this.props.userProfile.legalStatus}</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label for="bank_branch_name">DATE JOINED</label>
                        <p className="m-0 fs-5">{this.props.userProfile.dateJoined ? moment.unix(this.props.userProfile.dateJoined / 1000).format('ll') : ""}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clear"></div>
              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Account</h3>
              </div>
              <div className="col-lg-8 mt-1 id_date_box h-auto pb-4">
                <div className="bg-opacity-25">
                  <div className="row d-flex align-items-center">

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="bank_branch_name">Email</label>
                        <p className="m-0 fs-5">{this.props.userProfile.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="bank_branch_name">Phone Number</label>
                        <p className="m-0 fs-5">{this.props.userProfile.phone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Personal Details</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="ba{ nk_branch_name">ID or Any other identification Number</label>
                      <p className="m-0 fs-5">{this.props.userProfile.idNumber}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Occupation</label>
                      <p className="m-0 fs-5">{this.props.userProfile.occupation}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Name of Establishment or Employer</label>
                      <p className="m-0 fs-5">{this.props.userProfile.nameOfEmployer}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Current Company</label>
                      <p className="m-0 fs-5">{this.props.userProfile.currentCmpany}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Directorship in other Companies</label>
                      <p className="m-0 fs-5">{this.props.userProfile.directorsInOtherCmpanies}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Nationality</label>
                      <p className="m-0 fs-5">{this.props.userProfile.nationalityOfEmployer}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Current Networth</label>
                      <p className="m-0 fs-5">{this.props.userProfile.currentNetworth}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Current / Communication Address</label>
                      <p className="m-0 fs-5">{this.props.userProfile.communicationAddress}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Permanent Address</label>
                      <p className="m-0 fs-5">{this.props.userProfile.permanentAddress}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Type of Client</label>
                      <p className="m-0 fs-5">{this.props.userProfile.typeOfClients}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Risk Assesment</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  {/* <div className="col-lg-12 my-2"><hr /></div> */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Risk Level</label>
                      <p className="m-0 fs-5">{this.props.userProfile.riskLevel}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Any cases or issues determined against PFP client</label>
                      <p className="m-0 fs-5">{this.props.userProfile.casesAgainstPFPClient}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Bank details</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Bank name</label>
                      <p className="m-0 fs-5">{this.props.userProfile.bankName}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Account Number</label>
                      <p className="m-0 fs-5">{this.props.userProfile.accountNumber}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Branch Name</label>
                      <p className="m-0 fs-5">{this.props.userProfile.branchName}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Documents</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  {/* <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Bank Document</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("bankDetails")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div> */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Emirates ID</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycEmiratesId")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Passport Copy</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycPassport")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Residential Address Proof</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycResidentialAddressProof")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Signed Copy of the transaction Capital Document</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("signedCopyOfTCD")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">HNW Certificate ( if available )</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycHnwCertificate")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Bank Statement</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("proofOfSourceOfFund_1")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Salary Certificate</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("proofOfSourceOfFund_2")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    )
  }



  corporateInvestor = () => {
    return (
      <div className="col-lg-12 mt-3">
        <div className="w-auto">
          <div className="rounded_lg p-4 px-0">
            <h3 className="rounded pt-2">Corporate</h3>
            <form className="userProfile">
              <div className="col-lg-8 boxNot">
                <div className="id_date_box themeBG d-flex mt-0">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label for="bank_branch_name">ID</label>
                      <p className="m-0 fs-5">{this.props.userProfile.uniqueId}</p>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label for="bank_branch_name">STATUS</label>
                      <p className="m-0 fs-5">{this.props.userProfile.legalStatus}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">DATE JOINED</label>
                      <p className="m-0 fs-5">{this.props.userProfile.dateJoined ? moment.unix(this.props.userProfile.dateJoined / 1000).format('ll') : ""}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clear"></div>
              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Account</h3>
              </div>
              <div className="col-lg-8 mt-1 id_date_box h-auto pb-4">
                <div className="bg-opacity-25">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label for="bank_branch_name">Email</label>
                        <p className="m-0 fs-5">{this.props.userProfile.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 ml-n1">
                      <div className="form-group">
                        <label for="bank_branch_name">Phone Number</label>
                        <p className="m-0 fs-5">{this.props.userProfile.phone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Company Details</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Date of Incorporation</label>
                      <p className="m-0 fs-5">{moment.unix(this.props.userProfile.dateOfIncorporation / 1000).format('ll')}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Office Contact Number</label>
                      <p className="m-0 fs-5">{this.props.userProfile.officeContactNo}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Contact details of legal or Compliance (if any)</label>
                      <p className="m-0 fs-5">{this.props.userProfile.conractDetailOfLegal}</p>
                    </div>
                  </div>
                  <div className="col-lg-11 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Principal business activity</label>
                      <p className="m-0 fs-5">{this.props.userProfile.principalBusinessActivity}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Registered Office Address</label>
                      <p className="m-0 fs-5">{this.props.userProfile.regOfficeAddress}</p>
                    </div>
                  </div>

                  {/* <div className="col-lg-11 my-2"><hr /></div> */}
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="d-block" for="bank_branch_name">Confirmation Investment</label>
                      <p className="m-0 fs-5">{this.props.userProfile.investmentFromOwnAccount}</p>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Risk Assesment</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Risk Level</label>
                      <p className="m-0 fs-5">{this.props.userProfile.riskLevel}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Any Association of company with PEPs</label>
                      <p className="m-0 fs-5">{this.props.userProfile.associateWithPEPDetail ? "Yes" : "No"}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Any cases or issues determined against PEP Client</label>
                      <p className="m-0 fs-5">{this.props.userProfile.casesAgainstPFPClient}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Authorized Personnel Details</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Personnel Name</label>
                      <p className="m-0 fs-5">{this.props.userProfile.authPersonName}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Personnel Phone Number</label>
                      <p className="m-0 fs-5">{this.props.userProfile.authPersonPhone}</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Politically Exposed Person</label>
                      <p className="m-0 fs-5"></p>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Bank details</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Bank name</label>
                      <p className="m-0 fs-5">{this.props.userProfile.bankName}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Account Number</label>
                      <p className="m-0 fs-5">{this.props.userProfile.accountNumber}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Branch Name</label>
                      <p className="m-0 fs-5">{this.props.userProfile.branchName}</p>
                    </div>
                  </div>
                </div>
              </div>



              <div className="col-lg-12">
                <h3 class="mt-4 mb-0">Documents</h3>
              </div>
              <div className="col-lg-11 mt-1 id_date_box h-auto pb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Certificate of Incorporation / Commercial License</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycCertificateOfInorporation")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Memorandum and AoA</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycMemorandon")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">List of Directors</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycListOfDirectors")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>

                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Proof of Registered Office</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycProogOfRegisteredOffice")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">VAT Registration</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycVatRegistration")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Passport / EID of Authorized Signatories</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycPassportEidOfAuthSignatories")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Passport / EID of Authorized Personnel</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("kycPassportEidOfAuthPersonnel")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Recent Audited Financials or Management Accounts</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("recentAuditedAccounts")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>
                  <div className="col-lg-12 my-2"><hr /></div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label for="bank_branch_name">Signed Copy of the Transaction Capital Document</label>
                      {this.props.userDocument ?
                        <a href={this.displayFile("signedCopyOfTCD")} target="_blank"><FontAwesomeIcon icon={faEye} color="var(--bg-theme)" className="ms-2" /></a>
                        : ""}
                    </div>
                  </div>

                </div>
              </div>

            </form>
          </div>
        </div>
      </div>


    )
  }



  render() {
    return (
      <div>
        <Layout />
        {this.state.viewDocumentLoading
          ? <div className="loader">
            <Spinner animation="border" />
          </div >
          :
          <div id="container">
            <div className="viewMerchant_container">
              <h2 className="border-bottom pb-3 mb-2 teal fs-2 fwbold"><FontAwesomeIcon icon={faUserCircle} size="md" color="var(--bg-theme)" /> {this.state.name}</h2>
              {
                this.props.userProfile && this.props.userProfile.legalStatus === "Corporate" ?
                  this.corporateInvestor() :
                  this.individualInvestor()
              }
              <br />&nbsp;
              <br />&nbsp;
              <br />&nbsp;
            </div>
          </div>
        }

        {this.state.openModal ?
          <PdfViewer
            openModal="true"
            closeModal={this.closeModal}
            fileData={this.state.PdfViewerData}
          /> : ""}

      </div>
    );
  }
}

const mapStateToProps = ({ userProfileReducer }) => {
  console.log("userProfileReducer.userDocument", userProfileReducer.userDocument);
  return {
    userProfile: userProfileReducer.userProfile,
    userDocument: userProfileReducer.userDocument.content
  };

}

const mapDispatchToProps = {
  getUserProfile: UserProfileActions.getUserProfile,
  getUserDocuments: UserProfileActions.getUserDocuments
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

