import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { InvoicesActions } from "../../../actions";
import NumberFormat from 'react-number-format';
// import Chart from 'react-apexcharts';

class NetPayments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            // series: [400004, 550000],
            // options: {
            //     chart: {
            //         type: 'donut',
            //     },
            //     labels: ["used", "Available"],
            //     dataLabels: {
            //         enabled: false,
            //     },
            //     colors: ['#169E9A', '#ffffff'],
            //     responsive: [{
            //         breakpoint: 480,
            //         options: {
            //             chart: {
            //                 width: 200
            //             },
            //             legend: {
            //                 position: 'bottom'
            //             }
            //         }
            //     }]
            // },

        }
    }
    componentDidMount() {
        this.props.getAllInvoicesData(this.props.merchantId, this.props.buyerPlatformId);
        this.props.socket.on('dashboardUpdate', () => {
            this.props.getAllInvoicesData(this.props.merchantId, this.props.buyerPlatformId).then(() => {
                //console.log("getAllInvoicesData", "socket")
            });
        })
    }


    sumInvoices = (item) => {
        let onDayInvoces = this.props.dateWiseInvoices[item];
        return onDayInvoces.reduce((accumulator, element) => {
            return accumulator + element.invoiceAmount;
        }, 0);
    }


    render() {
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal(false)}
                dialogClassName="modalRight"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0" closeButton>Upcoming Payments</Modal.Header>
                <Modal.Body>
                    <table class="table">
                        <tbody>
                            {this.props.netPaymentDate.length > 0 && this.props.netPaymentDate.map((invoiceDate, index) => (
                                <tr>
                                    <th>{index + 1}</th>
                                    <td>
                                        <p>Due Date</p>
                                        <h3 className="color-text">{invoiceDate}</h3>
                                    </td>
                                    <td>
                                        <p>Amount (AED)</p>

                                        <h3><NumberFormat value={this.sumInvoices(invoiceDate) ? (this.sumInvoices(invoiceDate)).toFixed(2) : '-'} displayType={'text'} thousandSeparator={true} /></h3>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = ({ InvoiceReducer }) => {
    return {
        netReceiable: InvoiceReducer.netReceiable,
        dateWiseInvoices: InvoiceReducer.dateWiseInvoices,
        netPaymentDate: InvoiceReducer.netPayment
    }
}

const mapDispatchToProps = {
    getAllInvoicesData: InvoicesActions.getAllInvoicesData
}
export default connect(mapStateToProps, mapDispatchToProps)(NetPayments)


