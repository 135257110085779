import t from "./types";
import { Api } from '../../services/httpService';

export const getUserProfile = (id) => async (dispatch) => {
    try {
        const res = await Api('get', `/pfpclient/${id}`);

        if (res.success === true) {
            await dispatch({ type: t.GET_USER_DATA, payload: res.body });
        }
    } catch (e) {
        console.log("Err", e);
    }
}


export const getUserDocuments = (id) => async (dispatch) => {

    let data = {
        investorId: id
    }
    try {
        const res = await Api('post', `/pfpclient/docView`, data);
        if (res.success === true) {
            await dispatch({ type: t.GET_USER_DOCUMENT, payload: res.body });
        }
    } catch (e) {
        console.log("Err", e);
    }
}