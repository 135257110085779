import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link, Redirect } from "react-router-dom";
import { faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

import Layout from "../HomeLayout/Layout";
import DashboardComponent from "../components/DashboardComponent/DashboardComponent";
import { url, alterNateImage } from "../constants";
import './HomeNew.css';

const Home = ({ socket }) => {
  let [renderData, setrenderData] = useState(false);
  const history = useHistory();
  const titleLogo = require('../assests/titleLogo.png');
  const mystyle = {
    container: {
      width: window.innerWidth - 245,
    },

    Company_Detail_Card: {
    },
    Bank_Detail_Card: {
    },
    Document_Detail_Card: {
      left: 704,
      top: 222,
    },
    Noon_card: {
      left: 46,
      top: 428,
    },
    Tredling_card: {
      left: 375,
      top: 428,
    },
    Rotana_card: {
      left: 704,
      top: 428,
    },

    blur_card: {
      color: "var(--color-dbd)",
    },
  };

  const [BankVerificationNum, setBankVerificationNum] = useState(-1)
  const [BankVerificationStatus, setBankVerificationStatus] = useState("Verify Now");
  const [BankVerificationResStatus, setBankVerificationResStatus] = useState(false);
  const [isNumVerified, setisNumVerified] = useState(false)

  const [Status, setStatus] = useState({
    BankStatus: "",
    CompanyStatus: "",
    DocumentStatus: ""
  })

  const [Verified_BP_ids, setVerified_BP_ids] = useState([])
  const [merchantData, setmerchantData] = useState({
    bank: "",
    company: "",
    buyerPlatforms: [],
    documentStatus: ""
  });
  //
  const [allBuyerPlatforms, setAllBuyerPlatforms] = useState([]);
  const [activeBuyerPlatforms, setActiveBuyerPlatforms] = useState([])
  const [AllBP_Logos, setAllBP_Logos] = useState({});
  const [isActive, setIsActive] = useState(false)
  const [dashboardPage, setDashboardPage] = useState(false);
  const [ConditionsdashboardPage, setConditionsdashboardPage] = useState({
    ForDashboard_companyStatus: "",
    ForDashboard_bankStatus: "",
    ForDashboard_documentStatus: "",
    ForDashboard_buyerPlatFormStatus: false
  });

  const [isHomePage, setIsHomePage] = useState(false);

  let id = window.localStorage.getItem("_id");

  useEffect(() => {
    
  }, []);

  useEffect(() => {

    if (profileStatusComp() == "Completed" && profileStatusBank() == "Verified" && profileStatusDocument() == "Verified") {
      // history.push(`/user/dashboard/${id}`)  
      setDashboardPage(true);
    }
  }, [Status.BankStatus, Status.CompanyStatus, Status.DocumentStatus]);


  const loadMerchantData = async () => {
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    const apiUrl = `${url}/merchant/${id}`;
    // console.log("apiUrl",apiUrl);
    let user = {};
    let company = "";
    let bank = "";
    fetch(apiUrl, { headers: { "Authorization": tokenHeader } })
      .then((res) => res.json())
      .then((data) => {
        //console.log("home data", data);

        if (data.success != true) {
          //console.log("Inside if");
          window.localStorage.clear();
          window.location = "/"
          // history.push('/');
        }
        let buyerPlatforms = data.body.buyerPlatforms;
        let isVarified = buyerPlatforms.some((element) => {
          return element.status.toLowerCase() === "verified";
        })
        //adding id of buyer platforms who is verified so that in select BP section it cannot be unselected.
        let ver_BP_temp = [...Verified_BP_ids];
        buyerPlatforms.map(item => {
          if (item.status == 'VERIFIED') {
            ver_BP_temp.push(item.buyerPlatform.id);
          }
        });
        setVerified_BP_ids(ver_BP_temp)
        //alert("home")
        user = data.body.user;
        company = data.body.company.profileStatus;
        bank = data.body.bank.profileStatus;
        //console.log("in load status", data.body.bank.profileStatus, data.body.company.profileStatus, data.body.documentStatus)
        setmerchantData({
          ...merchantData,
          bank: data.body.bank,
          company: data.body.company,
          buyerPlatforms: data.body.buyerPlatforms,
          documentStatus: data.body.documentStatus
        });
        setStatus({
          BankStatus: data.body.bank.profileStatus,
          CompanyStatus: data.body.company.profileStatus,
          DocumentStatus: data.body.documentStatus
        });
        setConditionsdashboardPage({
          ForDashboard_companyStatus: data.body.company.profileStatus,
          ForDashboard_bankStatus: data.body.bank.profileStatus,
          ForDashboard_documentStatus: data.body.documentStatus,
          ForDashboard_buyerPlatFormStatus: isVarified
        });
        //toavoid rendering home page for seconds
        if (data.body.company.profileStatus != "COMPLETED"
          || data.body.bank.profileStatus != "VERIFIED"
          || data.body.documentStatus != "VERIFIED"
          || isVarified != true) {
          setIsHomePage(true)
        } else {
          setIsHomePage(false)
        }


      })
      .catch((err) => {
        window.localStorage.clear();
        window.location = "/"
        //console.log("loadMerchantData", err)
      });


  };

  const profileStatusComp = () => {
    if (merchantData.company.id == undefined) return "Fill Now";
    else if (merchantData.company.profileStatus === "VERIFICATION_PENDING")
      return "Verification Pending";
    else if (merchantData.company.profileStatus === "VERIFIED")
      return "Verified";
    else if (merchantData.company.profileStatus === "INCOMPLETE")
      return "Incomplete";
    else return "Completed";
  };

  const colorOfStatusCompany = () => {
    if (merchantData.company.profileStatus == "VERIFIED") return "#169E9A";
    else if (merchantData.company.profileStatus === "VERIFICATION_PENDING") return "#DBAA55";
    else if (merchantData.company.profileStatus === "INCOMPLETE") return "var(--red)";
    else return "#169E9A";
  };

  //Bank Status
  const profileStatusBank = () => {
    if (merchantData.bank.id == undefined) return "Fill Now";
    else if (merchantData.bank.profileStatus === "VERIFICATION_PENDING"
      || merchantData.bank.profileStatus === "VERIFICATION_INITIATED"
      || merchantData.bank.profileStatus === "VERIFICATION_ERROR" || BankVerificationStatus == "Verification error")
      return "Verification Pending";
    else if (merchantData.bank.profileStatus === "VERIFIED" || isNumVerified || BankVerificationStatus == "Verified Successfully")
      return "Verified";
    else if (merchantData.bank.profileStatus === "INCOMPLETE")
      return "Incomplete";

  };

  const colorOfStatusBank = () => {
    if (merchantData.bank.profileStatus == "VERIFIED") return "#169E9A";
    else if (merchantData.bank.profileStatus === "VERIFICATION_PENDING"
      || merchantData.bank.profileStatus === "VERIFICATION_INITIATED"
      || merchantData.bank.profileStatus === "VERIFICATION_ERROR") return "#DBAA55";
    else if (merchantData.bank.profileStatus === "INCOMPLETE") return "var(--red)";
    else return "#169E9A";
  };
  //console.log("dashboar", ConditionsdashboardPage)
  //for Status of Document
  const profileStatusDocument = () => {
    if (merchantData.documentStatus == "VERIFIED") return "Verified";
    else if (merchantData.documentStatus === "VERIFICATION_PENDING") return "Verification Pending";
    else if (merchantData.documentStatus === "INCOMPLETE") return "Incomplete";
    else return "Fill Now";
  };
  //for color of Status of Document
  const colorOfStatusDocument = () => {
    if (merchantData.documentStatus == "VERIFIED") return "#169E9A";
    else if (merchantData.documentStatus === "VERIFICATION_PENDING") return "#DBAA55";
    else if (merchantData.documentStatus === "INCOMPLETE") return "var(--red)";
    else return "#169E9A";
  };

  const profileStatusBP = (val) => {
    if (val.status == "VERIFIED") return "Verified";
    else if (val.status === "VERIFICATION_PENDING") return "Verification Pending";
    else if (val.status === "INCOMPLETE") return "Incomplete";
    else return "Fill Now";
  };

  const colorOfStatusBP = (val) => {
    if (val.status == "VERIFIED") return "#169E9A";
    else if (val.status === "VERIFICATION_PENDING") return "#DBAA55";
    else if (val.status === "INCOMPLETE") return "var(--red)";
    else return "#169E9A";
  };

  const addBuyerPlatform = () => {
    window.location = `/user/home/BuyerPlatforms/${id}`;
  }

  const OpenSelectBuyerPlatform_SidePanel_Handler = () => {
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    let self = this;
    let tempBuyerPlatforms = [];
    const response = axios({
      method: "get",
      url: url + "/buyerPlatforms?isActive=true",
      headers: { "Authorization": tokenHeader }

    })
      .then(function (response) {
        const s = response.status;
        const n = 200;
        //console.log("response 1", response.data._embedded.buyerPlatforms);
        tempBuyerPlatforms = response.data._embedded.buyerPlatforms;
        if (s === n) {
          setAllBuyerPlatforms(response.data._embedded.buyerPlatforms);

          let tmpState = [];
          response.data._embedded.buyerPlatforms.map((val) => {
            tmpState[val.id] = false;
          });

          let ans = isActive;
          merchantData.buyerPlatforms.map(val => {
            tmpState[val.buyerPlatform.id] = true;
            ans = true;
          })
          setActiveBuyerPlatforms(tmpState);
          setIsActive(ans);

          //console.log("tempBuyerPlatforms  ", tempBuyerPlatforms)
          tempBuyerPlatforms.map((item, index) => {
            let buyerID = item.id;
            if (item.logo != null) {
              var requestOptions = {
                method: "GET",
                redirect: "follow",
                headers: { "Authorization": tokenHeader }
              };
              let self = this;
              fetch(`${url}/files/${item.logo.id}`, requestOptions)
                .then((response) => response.blob())
                .then((result) => {
                  //console.log("result of logo in buyerP", result);
                  var reader = new FileReader();
                  reader.readAsDataURL(result);
                  reader.onloadend = function () {
                    var base64data = reader.result;
                    let temp = { ...AllBP_Logos };
                    temp[buyerID] = base64data;
                    setAllBP_Logos(AllBP_Logos => ({ ...AllBP_Logos, [buyerID]: base64data }))
                    //self.setState({ ...self.state, logoIcon: temp });

                  };
                })
                .catch((error) => console.log("error", error));
            }
          });

        } else alert("Invalid Credentials");
      })
      .catch(function (error) {
        console.log(error);
        alert("Invalid things...");
      });


    document.getElementById("SelectBuyerPlatform_SidePanel").style.width = "750px";
  }

  const CloseSelectBuyerPlatform_SidePanel_Handler = () => {
    document.getElementById("SelectBuyerPlatform_SidePanel").style.width = "0px";
  }

  //for opening the side sliding info panel 
  const onOpenSideBarInfo = (proposal) => {
    document.getElementById("myInfoPanel").style.width = "430px";
  }

  //for closing the side sliding info panel 
  const onCloseSideBarInfo = () => {
    document.getElementById("myInfoPanel").style.width = "0px";

  }

  //PUT req for sending the Verification amount so that it gets verified in back-end
  const onVerifyingBankAccountHandler = () => {
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    //console.log("setBankVerificationNum", BankVerificationNum);
    var requestOptions = {
      method: 'PUT',
      redirect: 'follow',
      headers: { "Authorization": tokenHeader }
    };
    fetch(`${url}/merchant/${id}/bank/verify?amount=${BankVerificationNum}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log("bank/verify?", result);
        if (result.success) {
          setBankVerificationResStatus(true)
          setisNumVerified(true);
          loadMerchantData();
          document.getElementById("myInfoPanel").style.width = "0px";
        } else {
          setBankVerificationResStatus(true)
          setisNumVerified(false);
        }
        setBankVerificationStatus(result.message)

      })
      .catch(error => console.log('error', error));
  }

  const Select_BP_Handler = (index) => {
    let ans = false;
    const tmpState = activeBuyerPlatforms.map((val, tmpIndex) => {

      if (tmpIndex === index) {

        let isThisBP_verified = false;
        Verified_BP_ids.map(item => {
          if (index == item) {
            isThisBP_verified = true;
          }
        })
        ans = ans || !val;
        if (isThisBP_verified) {
          return val;
        }
        else {
          return !val;
        }

      }
      else {
        ans = ans || val;
        return val;
      }
    });
    setActiveBuyerPlatforms(tmpState);
    setIsActive(ans);
  }

  const OnDoneSelecting_BP_Handler = () => {
    let self = this;
    let bpIds = [];
    activeBuyerPlatforms.map((val, index) => {
      if (val === true) {
        bpIds.push(index.toString());
      }
    });

    let Ids = "";
    bpIds.map((val, index) => {
      Ids = Ids + val + ",";
    });
    Ids = Ids.slice(0, -1);
    //console.log("Selected BP IDs : ", Ids);
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    axios({
      method: "put",
      url:
        url +
        "/merchantBuyerPlatforms/" +
        "?bp_id=" +
        Ids +
        "&m_id=" +
        id,
      headers: { "Authorization": tokenHeader }
    })
      .then(function (response) {
        const s = response.status;
        const n = 200;
        if (s === n) {
          setmerchantData({ ...merchantData, buyerPlatforms: response.data.body.buyerPlatforms })
          document.getElementById("SelectBuyerPlatform_SidePanel").style.width = "0px";

        }
        else alert("Invalid Credentials");
      })
      .catch(function (error) {
        //console.log(error);
        alert("Invalid things..");
      });
  }
  //console.log("isHomePage", isHomePage)
  return (
    <div>
      {
        (ConditionsdashboardPage.ForDashboard_companyStatus == "COMPLETED"
          && ConditionsdashboardPage.ForDashboard_bankStatus == "VERIFIED"
          &&
          ConditionsdashboardPage.ForDashboard_documentStatus == "VERIFIED")
          && ConditionsdashboardPage.ForDashboard_buyerPlatFormStatus ?

          <DashboardComponent id={id} socket={socket} />
          :
          (isHomePage) ?
            <div>
              <Layout />
              <div id="container">

                <div className="HomeConatiner" style={mystyle.container}>

                  <div className="row">
                    <div className="col-sm-3">
                      <div className="body-title">
                        <span className="box"><img src={titleLogo} /></span>
                        <p className="body-title-text">Welcome to Funder.ai</p>
                        <p className="title-text">
                          Please complete the steps below for quick verification of your
                          profile. Once your profile is verified, you can immediately start
                          factoring.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ /* marginTop: 160 */ }}>
                    <div className="col-sm-11">
                      <div className="row mt-3 ml-5">
                        <p className="sub_title">
                          <b>Step 1</b> &nbsp;&nbsp; Tell us about your Company
                        </p>
                      </div>

                      <div className="row ml-4 " style={{ marginTop: 16 }}>
                        <div className="col-sm-auto">
                          <Link exact to={`/user/companyDetails/`}>
                            <div id="card">
                              <p className="Card_label">
                                <b>Company Details</b>
                              </p>
                              <div className="row">
                                <p className="card_Text">
                                  Trade name, license, address, signing authority etc.
                                </p>
                                <p className="row fillNow"
                                  style={profileStatusComp() == "Verification Pending" ?
                                    { marginLeft: 140 } : profileStatusComp() == "Fill Now" ? { marginLeft: 200 } : { marginLeft: 120 }, { color: colorOfStatusCompany() }} >
                                  {profileStatusComp()} &nbsp;{" "}
                                  <span>
                                    {" "}
                                    <FontAwesomeIcon icon={faPlay} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>

                        <Link exact to={`/user/bankDetails`}>
                          <div className="col-sm-auto " style={{ marginLeft: 16 }}>
                            <div id="card">
                              <p className="Card_label">
                                <b>Bank Details</b>
                              </p>
                              <div className="row">
                                <p className="card_Text">
                                  Bank account number, bank name, branch, name.
                                </p>
                                <p className="row fillNow"
                                  style={profileStatusBank() == "Verification Pending" ?
                                    { marginLeft: 140, color: colorOfStatusBank() } : { marginLeft: 190, color: colorOfStatusBank() }}  >
                                  {profileStatusBank()} &nbsp;{" "}
                                  <span>
                                    {" "}
                                    <FontAwesomeIcon icon={faPlay} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <Link exact to={`/user/documents`}>
                          <div className="col-sm-auto " style={{ marginLeft: 16 }}>
                            <div id="card">
                              <p className="Card_label">
                                <b>Documents</b>
                              </p>
                              <div className="row">
                                <p className="card_Text">
                                  Upload trade license, balance sheet etc.
                                </p>
                                <p className=" row fillNow"
                                  style={profileStatusDocument() == "Verification Pending" ?
                                    { marginLeft: 140, color: colorOfStatusDocument() } : { marginLeft: 198, color: colorOfStatusDocument() }}>
                                  {profileStatusDocument()} &nbsp;{" "}
                                  <span>
                                    {" "}
                                    <FontAwesomeIcon icon={faPlay} />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row ml-5" style={{ marginTop: 24 }}>
                    <p className="sub_title">
                      <b>Step 2</b> &nbsp;&nbsp; Tell us about your Buyer Platform
                    </p>
                  </div>

                  <div className="row ml-4" style={{ marginTop: 8 }}>
                    <div className="col-sm-7" style={{ border: '1px solid white' }}>
                      <div className="row" >
                        <div id={merchantData.buyerPlatforms.length > 3 ? "wrapper" : ""} >
                          {
                            merchantData.buyerPlatforms.map((val, index) => (
                              <div key={val.buyerPlatform.id} style={{ width: 345 * (index + 1) }} >
                                <div >
                                  <Link exact to={`/user/setupbuyerplatform/${val.buyerPlatform.id}`}  >
                                    <div className="thecard" style={{ marginRight: 33 }}>
                                      <p className="Card_label">
                                        <b>{val.buyerPlatform.name}</b>
                                      </p>
                                      <div className="row">
                                        <p id="buyercard_Text" className="col-sm-auto">{val.buyerPlatform.typeDescription}</p>
                                        <p className="col-sm-auto cardfillNow"
                                          style={profileStatusBP(val) == "Verification Pending" ?
                                            { marginLeft: 105, color: colorOfStatusBP(val) } : { marginLeft: 172, color: colorOfStatusBP(val) }}
                                        >
                                          {profileStatusBP(val)} &nbsp;{" "}
                                          <span>
                                            {" "}
                                            <FontAwesomeIcon icon={faPlay} />
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-5" style={{ marginLeft: 0, border: '1px solid white' }}>
                      <div style={{ cursor: "pointer" }} onClick={() => OpenSelectBuyerPlatform_SidePanel_Handler()}>
                        <div className="H_addBuyerPlatforminHome" >
                          <p className="H_addSign mt-2" style={{ textAlign: "center", marginLeft: 10 }}>+</p>
                        </div>
                        <p className="addBuyerPlatforminHome_text">Add Buyer Platform</p>
                      </div>
                    </div>
                  </div>

                  {/* End of Buyer Platform Section */}


                  {/* BankVerification Section */}
                  {
                    /* Status.BankStatus != "VERIFIED" && */
                    (merchantData.bank.profileStatus === "VERIFICATION_PENDING"
                      || merchantData.bank.profileStatus === "VERIFICATION_ERROR"
                    ) ?
                      <div className="row" style={{ marginTop: 0 }}>
                        <div className="col-sm-11">
                          <div className="row ml-5 mt-5">
                            <p className="sub_title">
                              <b>Step 3</b> &nbsp;&nbsp; Verify your Bank Account
                            </p>
                          </div>

                          <div className="row ml-4 " style={{ marginBottom: 36, marginTop: 16 }}>
                            <div className="col-sm-auto">

                              <div id="card" style={{ width: 366, cursor: "pointer" }} onClick={() => onOpenSideBarInfo()}>
                                <p className="Card_label">
                                  <b>Bank Verification</b>
                                </p>
                                <div className="row">
                                  <p className="card_Text">
                                    We’ve credited a test amount. Please enter it to verify account.
                                  </p>
                                  <p className="row fillNow"
                                    style={BankVerificationStatus == 'Verify Now' ? { marginLeft: 280 } : { marginLeft: 210 }}>
                                    {BankVerificationStatus}&nbsp;{" "}
                                    <span>
                                      {" "}
                                      <FontAwesomeIcon icon={faPlay} />
                                    </span>
                                  </p>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="row" style={{ marginTop: 0, opacity: 0.3 }}>
                        <div className="col-sm-11">
                          <div className="row ml-5 mt-5">
                            <p className="sub_title">
                              <b>Step 3</b> &nbsp;&nbsp; Verify your Bank Account
                            </p>
                          </div>

                          <div className="row ml-4 " style={{ marginBottom: 36, marginTop: 16 }}>
                            <div className="col-sm-auto">

                              <div id="card" style={{ width: 366 }}>
                                <p className="Card_label">
                                  <b>Bank Verification</b>
                                </p>
                                <div className="row">
                                  <p className="card_Text">
                                    We’ve credited a test amount. Please enter it to verify account.
                                  </p>
                                  <p className="row fillNow"
                                    style={BankVerificationStatus == 'Verify Now' ? { marginLeft: 280 } : { marginLeft: 210 }}>
                                    {merchantData.bank.profileStatus == "VERIFIED" ? "Verified" : ""}&nbsp;{" "}
                                    <span>
                                      {" "}
                                      {/* <FontAwesomeIcon icon={faPlay} /> */}
                                    </span>
                                  </p>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>

                  }
                  {/* End of Bank verification sec */}


                  {/* Info Panel */}
                  <div id="myInfoPanel" className="infoPanel">
                    <div className="row" style={{ paddingLeft: 25, paddingLeft: 40 }} id="upperSect">

                      <div className="col-sm-11">
                        <div className="row">
                          <div className="col-sm-10 panelText" style={{ fontSize: 36 }}>
                            Bank Verification
                          </div>
                          <div className="col-sm-1 text-right">
                            <span className="closebtn mt-1 cursor" onClick={() => onCloseSideBarInfo()}><b>x</b></span>
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-sm-11 panelLabel">
                            We’ve credited a test amount. Please enter it to verify bank account.
                          </div>
                        </div>

                        <div className="row ml-1 panelText mt-5"  >
                          AED
                        </div>
                        <div className="row ml-1 mt-3">
                          <input type="number" className="col-sm-6 H_AED_imp"
                            onChange={(e) => { setBankVerificationNum(e.target.value) }}
                            placeholder="x x x x x" />
                        </div>

                        {
                          BankVerificationResStatus ?
                            <p className={isNumVerified ? "Active_VerifyNumStatus mt-3 ml-1" : "VerifyNumStatus mt-3 ml-1"} >
                              {isNumVerified ? "Number Verified." : "Incorrect Number, Try again!!"}
                            </p>
                            :
                            null
                        }


                        <div className="row ml-1">
                          <button className="bankVerify_H cursor"
                            style={BankVerificationResStatus ? { marginTop: 300 } : { marginTop: 300 }}
                            onClick={() => onVerifyingBankAccountHandler()}> Verify</button>
                        </div>



                      </div>
                    </div>
                  </div>

                  {/* End if Info Panel */}

                  {/* Select Buyer Platform side panel */}
                  <div id="SelectBuyerPlatform_SidePanel" className="SelectBuyerPlatform_SidePanel">

                    <div className="row" style={{ marginLeft: 40, marginTop: 30 }} >
                      <div className="col-sm-11">

                        <div className="row ">
                          <div className="col-sm-11 SBP_title" >
                            Select Buyer Platform
                          </div>
                          <div className="col-sm-1 text-right">
                            <span className="closebtn mt-1" onClick={() => CloseSelectBuyerPlatform_SidePanel_Handler()}><b>x</b></span>
                          </div>
                          <p className="row mt-2 ml-3 font" style={{ fontSize: 14, color: 'var(--color-c6c)' }}>Who do you sell to/ via?</p>
                        </div>

                        <div className="Thescroll" style={{ marginTop: 5 }}>
                          <div className="select_BP_Container row">
                            {allBuyerPlatforms.map((val, index) => (
                              <div className="col-sm-4 mt-3" key={val.id}>
                                <div
                                  onClick={() => Select_BP_Handler(val.id)}
                                  className={
                                    activeBuyerPlatforms[val.id]
                                      ? "imgButtonActive cursor"
                                      : "imgButton cursor"
                                  }>
                                  {
                                    AllBP_Logos[val.id] == null ? (
                                      <img
                                        src={alterNateImage}
                                        style={{ width: 73, height: 72, marginTop: 0 }}
                                      />
                                    ) : (
                                      <img
                                        src={AllBP_Logos[val.id]}
                                        style={{ width: 73, height: 72, marginTop: 0 }}
                                      />
                                    )}
                                </div>
                                <p
                                  className={
                                    activeBuyerPlatforms[val.id]
                                      ? "buttonLabelActive"
                                      : "buttonLabel"
                                  }
                                  style={{ marginRight: 40, marginTop: 8 }}
                                >
                                  {val.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>

                        <button
                          disabled={!isActive}
                          className={
                            !isActive
                              ? "CreateAccformSelectBPDisabled cursor"
                              : "CreateAccformSelectBP cursor"
                          }
                          style={{ bottom: 50, marginLeft: 12 }}
                          onClick={() => OnDoneSelecting_BP_Handler()}>Next</button>
                      </div>

                    </div>
                  </div>
                  {/* End of Select Buyer Platform side panel */}

                </div>
              </div>
            </div>

            :
            null
      }
    </div>

  );
};

export default Home;
