import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import './netReceiable.css';
import { InvoicesActions } from "../../../actions";
import moment from "moment";
import NumberFormat from 'react-number-format';
import { url } from '../../../constants';
import Spinner from "react-bootstrap/Spinner"



class NetReceiable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            pdfLink: '',
            viewDocumentLoading: false

        }
    }


    componentDidMount() {
        this.props.getAllInvoicesData(this.props.merchantId, this.props.buyerPlatformId);
        this.props.socket.on('dashboardUpdate', () => {
            this.props.getAllInvoicesData(this.props.merchantId, this.props.buyerPlatformId);
        })
    };
    getInvoicePdf = (invoicePdf) => {
        console.log("invoicePdf", invoicePdf);
        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        var requestOptions = {
            method: "GET",
            headers: { "Authorization": tokenHeader },
        };

        this.setState({ viewDocumentLoading: true });
        fetch(`${url}/invoices/getFile/${invoicePdf}`, requestOptions)
            .then((response) => response.blob())
            .then((result) => {
                var fileURL = URL.createObjectURL(result);
                window.open(fileURL, "_blank");
                this.setState({ viewDocumentLoading: false });

            })

            .catch((error) => { console.log("error", error); this.setState({ viewDocumentLoading: false }); });

    }

    render() {
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal(false)}
                dialogClassName="modalRight"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0" closeButton>Net Receivable Breakup</Modal.Header>
                <Modal.Body>
                    {this.state.viewDocumentLoading ?
                        <div className="loadersheet">

                            <Spinner animation="border" />

                        </div >
                        :
                        <table class="table">
                            <tbody>
                                {this.props.netReceiable.length > 0 && this.props.netReceiable.map((invoice, index) => (
                                    <tr>
                                        <th>{index + 1}</th>
                                        <td>
                                            <p>Invoice#</p>
                                            <h3 className="cursor"  onClick={() => this.getInvoicePdf(invoice.invoicePdf)} >{invoice.invoiceNumber}</h3><br />
                                            <a className="cursor" onClick={() => this.getInvoicePdf(invoice.invoicePdf)} >View Invoice</a>
                                        </td>
                                        <td>
                                            <p>Amount</p>
                                            <h3><NumberFormat value={invoice.invoiceAmount ? (invoice.invoiceAmount).toFixed(2) : '-'} displayType={'text'} thousandSeparator={true} /></h3>
                                        </td>
                                        <td>
                                            <p>Due Date</p>
                                            <h3>{invoice.dueDate ? moment.unix(invoice.dueDate / 1000).format('ll') : ""}</h3>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                </Modal.Body>
            </Modal>

        )
    }
}

const mapStateToProps = ({ InvoiceReducer }) => {
    return {
        netReceiable: InvoiceReducer.netReceiable
    }
}

const mapDispatchToProps = {
    getAllInvoicesData: InvoicesActions.getAllInvoicesData
}
export default connect(mapStateToProps, mapDispatchToProps)(NetReceiable)


