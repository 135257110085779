import React, { Component } from "react";

//import Header from "./Header/Header";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

// import Merchants from "./components/Merchant";
import "../node_modules/bootstrap/dist/css/bootstrap.css";

// import BPS from "./pages/BuyerPlatformSetup/BuyerPlatformSetup";
// import BuyerPlatformSetup from "./pages/BuyerPlatformSetup/BuyerPlatformSetup";
import ParentContainerComponent from "./ParentContainerComponent";
import { socketUrl } from './constants'
import * as socketIoClient from "socket.io-client";
//import socketIoClient from 'socket.io-client'
import Dashboard from './components/Dashboard/dashboard'
import Login from './components/LandingScreen/LandingScreen'
import Home from './HomeComponent/Home'
import UserCommonBackground from './components/UserCommonBackground/UserCommonBackground'
import UserProfile from './pages/UserProfile/UserProfile'

import "typeface-roboto";
import "./App.css";

// const Home = React.lazy(() => import("./HomeComponent/Home"))
class App extends Component {

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" render={props => <Dashboard {...props} />} />
            <Route exact path="/login" render={props => <UserCommonBackground {...props} />} />
            <Route exact path="/userProfile" render={props => <UserProfile {...props} />} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
