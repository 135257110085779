import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';


class PdfViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }

    render() {
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal()}
                dialogClassName="modalRight"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0" closeButton></Modal.Header>
                <Modal.Body>
                    <embed src={`data:application/pdf;base64,${this.props.fileData}#toolbar=0`} type="application/pdf" height="80%" width="100%"></embed>
                </Modal.Body>
            </Modal>

        )
    }
}


export default PdfViewer;


