import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { url } from "../../../constants";
import "./style.css";
import ContentLoader, { Facebook } from "react-content-loader";
import isEqual from 'lodash/isEqual'
import PlatformModal from '../LinkedWith/LinkedWith'

class SelectPlatform extends Component {
  state = {
    btnDisabled: true,
    activeBuyerPlatforms: [],
    allBuyerPlatforms: [],
    traditionalPlatFroms: [],
    ecommercePlatFroms: [],
    workingCapitalPlatFroms: [],
    orActives: false,
    logoIcon: {},
    buyerPlatformImageLoading: true,
    openModal: false,
    linkedWithBuyerPlatform: [],
    linkedPlatform: {},
    modalValue: [],
    selectedValueFromModal: {},
    selectedLinkedWithId: {}
  };

  componentDidMount() {
    this.loadData();
  }

  activeOnes = () => {
    let tmpState = [];
    this.state.allBuyerPlatforms.map((val) => {
      tmpState[val.id] = false;
    });
    this.setState({ activeBuyerPlatforms: tmpState });
  };

  preSelection = () => {
    let tmpState = [...this.state.activeBuyerPlatforms];
    let ans = this.state.orActives;

    this.state.usersBuyerPlatforms.map((val) => {
      tmpState[val.buyerPlatform.id] = true;
      ans = true;
    });
    this.setState({ activeBuyerPlatforms: tmpState, orActives: ans });
  };

  loadData = async () => {
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    let self = this;
    let tempBuyerPlatforms = [];
    const response = await axios({
      method: "get",
      url: url + "/buyerPlatforms?isActive=true",
      headers: { "Authorization": tokenHeader }
    }).then(function (response) {
      const s = response.status;
      const n = 200;
      //console.log("response 1", response.data._embedded.buyerPlatforms);
      tempBuyerPlatforms = response.data._embedded.buyerPlatforms;

      if (s === n) {
        self.setState({
          allBuyerPlatforms: response.data._embedded.buyerPlatforms,
        });
        self.activeOnes();
      } else alert("Invalid Credentials");
    })
      .catch(function (error) {
        console.log(error);
        alert("Invalid things...");
      });

    axios({
      method: "get",
      url: url + "/merchant/" + self.props.userId,
      headers: { "Authorization": tokenHeader }
    })
      .then(function (response) {
        // //console.log(JSON.stringify(response.data));

        const s = response.status;
        const n = 200;
        if (s === n) {
          self.setState({
            usersBuyerPlatforms: response.data.body.buyerPlatforms,
          });
          self.preSelection();
        } else alert("Invalid Credentials");
      })
      .catch(function (error) {
        console.log(error);
        alert("Invalid things");
      });

    tempBuyerPlatforms.map((item, index) => {
      let buyerID = item.id;

      if (item.logo != null) {
        var requestOptions = {
          method: "GET",
          redirect: "follow",
          headers: { "Authorization": tokenHeader }
        };
        let self = this;
        fetch(`${url}/files/${item.logo.id}`, requestOptions)
          .then((response) => response.blob())
          .then((result) => {
            //console.log("result of logo in buyerP", result);
            var reader = new FileReader();
            reader.readAsDataURL(result);
            reader.onloadend = function () {
              var base64data = reader.result;
              let temp = { ...self.state.logoIcon };
              temp[buyerID] = base64data;
              self.setState({ ...self.state, logoIcon: temp }, () => {
                setTimeout(() => { self.setState({ buyerPlatformImageLoading: false }) }, 100)

              });
              //console.log("state deo", self.state.logoIcon);
            };
          })
          .catch((error) => console.log("error", error));
      }
    });
  };

  closeModal = async (active, selectedValue, modalActives) => {
    this.setState({
      openModal: false
    })
    let unselectedValues = []
    for (let index = 0; index < modalActives.length; index++) {
      if (modalActives[index].id !== selectedValue.id) {
        unselectedValues.push(modalActives[index])
      }
    }
    let deletedValue = { ...this.state.selectedLinkedWithId }
    for (let index = 0; index < unselectedValues.length; index++) {
      delete deletedValue[unselectedValues[index].linkedWith]

    }
    deletedValue[selectedValue.linkedWith] = selectedValue
    this.setState({
      selectedLinkedWithId: deletedValue
    })
    this.state.ecommercePlatFroms.map((item, index) => {
      if (item.id === selectedValue.linkedWith) {
        this.setState(prevState => ({
          selectedLinkedWithId: { ...prevState.selectedLinkedWithId, [selectedValue.linkedWith]: selectedValue }
        }))
      }
    })
  }

  buttonPressed = (index, type, activeBuyerPlatformsData, modalActive) => {
    console.log("In button Pressed", index, type);
    if (modalActive) {
      // console.log('buyer',this.state.activeBuyerPlatforms[3])
      modalActive.map((item) => {
        this.state.activeBuyerPlatforms[item.id] = false
      })
      let ans = false;
      let dataArray = [...this.state.activeBuyerPlatforms]
      const tmpState = dataArray.map((val, tmpIndex) => {
        if (tmpIndex === index) {
          ans = true;
          return true;
        } else {
          ans = ans || val;
          return val;
        }
      });
      this.setState({ activeBuyerPlatforms: tmpState, orActives: ans });
      console.log(this.state.activeBuyerPlatforms);
      console.log(this.state.orActives);
      return;
    }
    const linkedWith = this.state.allBuyerPlatforms.filter((item) => {
      return item.linkedWith === index
    })

    if (!type || linkedWith.length == 0) {
      let ans = false;
      let activeData;
      if (activeBuyerPlatformsData) {
        activeData = activeBuyerPlatformsData
      } else {
        activeData = this.state.activeBuyerPlatforms
      }
      const tmpState = activeData.map((val, tmpIndex) => {
        if (tmpIndex === index) {
          ans = ans || !val;
          console.log(ans);
          return !val;
        } else {
          ans = ans || val;
          console.log(ans);
          return val;
        }
      });
      this.setState({ activeBuyerPlatforms: tmpState, orActives: ans });
      console.log(this.state.activeBuyerPlatforms);
      console.log(this.state.orActives);

    }
    if (type && linkedWith.length > 0) {
      const linked = this.state.allBuyerPlatforms.filter((item) => {
        return item.id === index
      })

      this.setState({
        linkedWithBuyerPlatform: linkedWith,
        linkedPlatform: linked,
        openModal: true
      })
    }
    // if(val){
    //   this.setState({selectedValueFromModal : val})
    // }
  }

  onDone = (event) => {
    let self = this;
    let bpIds = [];
    this.state.activeBuyerPlatforms.map((val, index) => {
      if (val === true) {
        bpIds.push(index.toString());
      }
    });

    let Ids = "";
    bpIds.map((val, index) => {
      Ids = Ids + val + ",";
    });
    Ids = Ids.slice(0, -1);
    console.log("Selected BP IDs : ", Ids);
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    axios({
      method: "put",
      url:
        url + "/merchantBuyerPlatforms/" + "?bp_id=" +
        Ids +
        "&m_id=" +
        self.props.userId,
      headers: { "Authorization": tokenHeader }

    })
      .then(function (response) {
        // //console.log(response);
        const s = response.status;
        const n = 200;
        if (s === n) {
          //console.log("data", response.data);

          let curr_loca = window.location.pathname;
          let loca_arr = curr_loca.split('/');

          window.location = "/user";
          //window.location = "/user/dashboard/" + self.props.userId;
        } else alert("Invalid Credentials");
      })
      .catch(function (error) {
        console.log(error);
        alert("Invalid things.");
      });
  };
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.allBuyerPlatforms, this.state.allBuyerPlatforms)) {
      const traditional = this.state.allBuyerPlatforms.filter((item) => {
        return item.typeDescription === "Traditional"
      })
      const ecommerce = this.state.allBuyerPlatforms.filter((item) => {
        return item.typeDescription === "E-Commerce"
      })
      const workingCapital = this.state.allBuyerPlatforms.filter((item) => {
        return item.typeDescription === "Working Capital" && item.linkedWith === 0
      })
      this.setState({
        traditionalPlatFroms: traditional,
        ecommercePlatFroms: ecommerce,
        workingCapitalPlatFroms: workingCapital
      })
    }
  }
  MyLoader = () => (
    <ContentLoader>
      <rect x="4" y="-18" rx="5" ry="5" width="110" height="110" />
    </ContentLoader>
  );
  render() {

    return (
      <div>
        <Modal
          show={this.props.openModal}
          onHide={() => this.props.closeModal()}
          dialogClassName="modalRight"
          aria-labelledby="example-custom-modal-styling-title" >
          <Modal.Header className="border-0" closeButton>Select Buyer Platform</Modal.Header>
          <Modal.Body>
            <div className="_form" >
              {/* <label className="welcome" >Select Buyer Platform</label> */}
              <p className="CaPara" style={{ marginBottom: 20 }}>Who do you want to sell to /via ?</p>
              <div className="Thescroll" style={{ width: '100%' }}>
                <h3>Traditional</h3>
                <div className="selectBPContainer row  " >
                  {this.state.traditionalPlatFroms.map((val, index) => (
                    <div className="col-sm-auto"
                      style={index == 0 || index == 1 /* || index == 2 || index == 3 */ ?
                        { padding: '0px 15px', marginTop: 16, textAlign: 'center' } :
                        { padding: '0px 15px', marginTop: 16, textAlign: 'center' }} key={val.id}>
                      {/* <p>{console.log("bal", val)}</p> */}
                      <button
                        onClick={() => this.buttonPressed(val.id)}
                        className={
                          this.state.activeBuyerPlatforms[val.id]
                            ? "imgButtonActive cursor"
                            : "imgButton cursor"
                        }
                        style={{ /* backgroundColor: "red"  */ }}
                      >
                        {this.state.logoIcon[val.id] == null
                          ? this.state.buyerPlatformImageLoading ? this.MyLoader() : (<p style={{ fontSize: 45 }}>NA</p>)
                          : this.state.buyerPlatformImageLoading ? this.MyLoader() : (<img
                            src={this.state.logoIcon[val.id]}
                            style={{ width: 52, height: 52, marginTop: 0/* , backgroundColor: "red" */ }} />)}
                      </button>
                      <p
                        className={
                          this.state.activeBuyerPlatforms[val.id]
                            ? "buttonLabelActive"
                            : "buttonLabel"
                        }
                        style={val.name.length > 5 ? { marginTop: 16 } : { marginTop: 16 }}
                      >
                        {val.name}
                      </p>
                    </div>
                  ))}
                </div>
                <h3 className="pt-2">Market Place &amp; Working Capital</h3>
                <div className="selectBPContainer row  " >
                  {this.state.ecommercePlatFroms.map((val, index) => (
                    <div className="col-sm-auto"
                      style={index == 0 || index == 1 /* || index == 2 || index == 3 */ ?
                        { padding: '0px 15px', marginTop: 16, textAlign: 'center' } :
                        { padding: '0px 15px', marginTop: 16, textAlign: 'center' }} key={val.id}>
                      {/* <p>{console.log("bal", val)}</p> */}
                      <button
                        onClick={() => this.buttonPressed(val.id, val.typeDescription)}
                        className={
                          Object.keys(this.state.selectedLinkedWithId).indexOf(val.id.toString()) > -1 ? this.state.activeBuyerPlatforms[this.state.selectedLinkedWithId[val.id].id]
                            ? "imgButtonActive cursor"
                            : "imgButton cursor" : this.state.activeBuyerPlatforms[val.id]
                            ? "imgButtonActive cursor"
                            : "imgButton cursor"

                        }
                        style={{ /* backgroundColor: "red"  */ }}
                      >
                        {this.state.logoIcon[val.id] == null
                          ? this.state.buyerPlatformImageLoading ? this.MyLoader() : (<p style={{ fontSize: 45 }}>NA</p>)
                          : this.state.buyerPlatformImageLoading ? this.MyLoader() : (<img
                            src={this.state.logoIcon[Object.keys(this.state.selectedLinkedWithId).indexOf(val.id.toString()) > -1 ? this.state.selectedLinkedWithId[val.id].id : val.id]}
                            style={{ width: 52, height: 52, marginTop: 0/* , backgroundColor: "red" */ }} />)}
                      </button>
                      <p
                        className={
                          Object.keys(this.state.selectedLinkedWithId).indexOf(val.id.toString()) > -1 ? this.state.activeBuyerPlatforms[this.state.selectedLinkedWithId[val.id].id]
                            ? "buttonLabelActive"
                            : "buttonLabel" :
                            this.state.activeBuyerPlatforms[val.id]
                              ? "buttonLabelActive"
                              : "buttonLabel"
                        }
                        style={val.name.length > 5 ? { marginTop: 16 } : { marginTop: 16 }}
                      >
                        {Object.keys(this.state.selectedLinkedWithId).indexOf(val.id.toString()) > -1 ? this.state.selectedLinkedWithId[val.id].name : val.name}
                      </p>
                    </div>
                  ))}
                </div>
                {this.state.workingCapitalPlatFroms.length > 0 ?
                  <>  <h3 className="pt-2">Working Capital</h3>
                    <div className="selectBPContainer row  " >
                      {this.state.workingCapitalPlatFroms.map((val, index) => (
                        <div className="col-sm-auto"
                          style={index == 0 || index == 1 /* || index == 2 || index == 3 */ ?
                            { padding: '0px 15px', marginTop: 16, width: 150, textAlign: 'center' } :
                            { padding: '0px 15px', marginTop: 16, width: 150, textAlign: 'center' }} key={val.id}>
                          {/* <p>{console.log("bal", val)}</p> */}
                          <button
                            onClick={() => this.buttonPressed(val.id)}
                            className={
                              this.state.activeBuyerPlatforms[val.id]
                                ? "imgButtonActive cursor"
                                : "imgButton cursor"
                            }
                            style={{ /* backgroundColor: "red"  */ }}
                          >
                            {this.state.logoIcon[val.id] == null
                              ? this.state.buyerPlatformImageLoading ? this.MyLoader() : (<p style={{ fontSize: 45 }}>NA</p>)
                              : this.state.buyerPlatformImageLoading ? this.MyLoader() : (<img
                                src={this.state.logoIcon[val.id]}
                                style={{ width: 72, height: 72, marginTop: 0/* , backgroundColor: "red" */ }} />)}
                          </button>
                          <p
                            className={
                              this.state.activeBuyerPlatforms[val.id]
                                ? "buttonLabelActive"
                                : "buttonLabel"
                            }
                            style={val.name.length > 5 ? { marginTop: 16 } : { marginTop: 16 }}
                          >
                            {val.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </> : ""}
              </div>
              <button
                disabled={!this.state.orActives}
                className={
                  !this.state.orActives
                    ? "CreateAccformSelectBPDisabled cursor"
                    : "CreateAccformSelectBP cursor"
                }
                type="submit"
                onClick={() => this.onDone()}
              >
                Next
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.openModal && (
          <PlatformModal
            openModal={this.state.openModal}
            closeModal={this.closeModal}
            linkedWith={this.state.linkedWithBuyerPlatform}
            linked={this.state.linkedPlatform}
            active={this.state.activeBuyerPlatforms}
            buttonPress={this.buttonPressed}
          />
        )}
      </div>
    )
  }
}

export default SelectPlatform;