import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import Layout from "../../HomeLayout/Layout";
import IframeResizer from 'iframe-resizer-react';
import CmsModal from '../Modals/CmsModal/CmsModal';


const Dashboard = () => {
    const [openModal, setOpenModel] = useState(false);
    const [callModelFor, setCallModelFor] = useState("");
    const name = localStorage.getItem("_name");
    const history = useHistory()
    useEffect(() => {
        if (!localStorage.getItem('_token')) {
            history.push('/login')
        }
    }, [])


    const showModal = (Data) => {
        setCallModelFor(Data);
        setOpenModel(true);
    }

    const closeModal = () => {
        setOpenModel(false);
    }


    return (
        <div>
            {console.log(openModal)}
            <Layout />
            <div id="container" className="mainWrap">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="welcome_title mt-lg-3">Welcome {name}</h1>
                    </div>
                    <div className="col-md-6 text-end" >
                        <p className="me-4 link_color" onClick={() => showModal("Disclaimer")}>
                            <span className="mt-lg-3">Disclaimer </span>
                        </p>
                        <p className="link_color" onClick={() => showModal("Terms & Conditions")}>
                            <span className="mt-lg-3">Terms & Conditions </span>
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <div className="dashbord-info_new">
                            <IframeResizer
                                src="https://metabase.efunder.ai/public/dashboard/4a1849f1-1551-4ed9-92f2-79fa3c28c417"
                                frameborder="0"
                                width="100%"
                                allowtransparency
                                scrolling="no"
                                heightCalculationMethod="lowestElement"
                            ></IframeResizer>
                        </div>
                    </div>
                </div>
            </div>
            <CmsModal
                openModal={openModal}
                closeModal={() => closeModal()}
                modalTitle={callModelFor}
            />
        </div >
    )
}

export default Dashboard;