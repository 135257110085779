// export const url = "https://factory.ezxdemo.com";
// export const socketUrl = 'http://127.0.0.1:4001';

export const alterNateImage = require('./assests/marketplace-logo.png');

//Production Url

// export const socketUrl = 'https://socket.efunder.ai';
// export const url = "https://java.efunder.ai";

//Staging Url

export const url = 'https://dsjava.efunder.ai';
export const socketUrl = 'https://socketstage.efunder.ai';
