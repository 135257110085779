import React, { useState, useEffect } from "react";
import './Header.css';
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import { url } from "../../constants";
import { MerchantActions } from "../../actions";
//import {FunderLogo} from '../assests/FunderLogo.jpg'; 
import { connect } from 'react-redux'
import Notifications from '../../components/Modals/Notifications/notifications'

const Header = ({ getNotifications, getAllNotifications, socket }) => {
    const [notificationsModal, setNotificationsModal] = useState(false)

    const logo_ = require('../../assests/logo-white.png');
    const logout = require('../../assests/logout.png');
    const bell = require('../../assests/bell.png');
    const name = localStorage.getItem("_name");

    // let {id} = useParams();
    let id = null;
    const history = useHistory();

    const [state, setState] = useState({
        userName: "",
        company: ""
    });

    const closeModal = (data) => {
        setNotificationsModal(data)
    }

    const mystyle = {
        headerstyle: {
            width: '100%'
        },
        namestyle: {
            left: '100%' - 165
        },
        solidcircle: {
            left: window.innerWidth - 225
        },
        bell: {
            left: window.innerWidth - 280
        },
        username: {
            left: window.innerWidth - 170
        },
        logoutBtn: {
            left: window.innerWidth - 100,
            marginTop: 20
        }

    };

    useEffect(() => {
        if (!localStorage.getItem("_token")) {
            history.push('/login')
        }
    }, []);

    const loadMerchantData = async (id) => {

        const apiUrl = `${url}/merchant/${id}`;
        //console.log("api", apiUrl);
        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        fetch(apiUrl, { headers: { "Authorization": tokenHeader } })
            .then((res) => res.json())
            .then((data) => {

                console.log(" header data", data.body);
                const userdata = data.body;

                const temp = { ...state };
                temp.name = userdata.user.name;
                temp.company = userdata.company.designation;
                setState(temp);
            })
            .catch(() =>
                console.log("Can’t access " + apiUrl + " response. Blocked by browser?")
            );
    };

    const openUserProfile = () => {
        history.push(`/userProfile`)
    }

    const LogOutHandler = () => {
        window.localStorage.clear();
        window.location.reload();
    }

    if (getAllNotifications) {
        var unreadNotificationCount = getAllNotifications.filter((item) => {
            return item.flag === 0

        })
        //console.log("unreadNotificationCount", unreadNotificationCount)
        // {setNotificationCount(unreadNotificationCount)}
    }

    const addClass = () => {
        const firstElement = document.getElementsByClassName('open')
        // console.log('firstElement', firstElement)
        if (firstElement.length > 0) {
            firstElement[0].classList.remove("open")
        } else {
            var element = document.getElementsByClassName('sidebar')
            element[0].classList.add("open");
        }
    }


    return (
        <div >
            <div id="main_Header" style={mystyle.headerstyle}>
                <div className="top_left_title cursor" onClick={addClass}>
                    <div id="toggle">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                    </div>
                    <img src={logo_} style={{ height: 45 }} />
                </div>

                <div className="col-6 right-content d-flex align-items-center justify-content-end">
                    {getAllNotifications && (getAllNotifications.filter((item) => { return item.flag === 0 })).length != 0
                        ? <div style={{
                            position: "absolute",
                            right: 220,
                            top: 10,
                            height: 22,
                            width: 22,
                            textAlign: "center",
                            borderRadius: 18,
                            backgroundColor: "red",
                        }}>
                            <text style={
                                getAllNotifications && (getAllNotifications.filter((item) => { return item.flag === 0 })).length > 9
                                    ? {
                                        color: "#ffffff",
                                        fontSize: 16,
                                        textAlign: 'center',
                                        borderRadius: 18,
                                        marginLeft: 3,
                                    }
                                    : {
                                        color: "#ffffff",
                                        fontSize: 16,
                                        textAlign: 'center',
                                        borderRadius: 18,
                                    }
                            }
                            >{getAllNotifications ? (getAllNotifications.filter((item) => { return item.flag === 0 })).length > 9 ? "9+" : (getAllNotifications.filter((item) => { return item.flag === 0 })).length : ''}</text>
                        </div>
                        : ''
                    }

                    {/* <img className="_circle position-relative" src={ellipse} width={16} height={16} /> */}

                    <div className="_name cursor" onClick={() => openUserProfile()}>
                        <text className="_headertext" >{name}</text>
                    </div>

                    <img className="_logout cursor" onClick={() => LogOutHandler()} src={logout} width={20} height={20} />

                </div>
            </div>
            {notificationsModal && getAllNotifications && (
                <Notifications
                    openModal={notificationsModal}
                    closeModal={closeModal}
                    data={getAllNotifications ? getAllNotifications : []}
                />
            )}
        </div>
    );
}

const mapStateToProps = ({ MerchantListReducer }) => {
    return {
        getAllNotifications: MerchantListReducer.getNotifications
    }


}

const mapDispatchToProps = {
    getNotifications: MerchantActions.getNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);