import React from "react";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import Loader from "react-loader-spinner";

const PaymentsComponent = React.lazy(() => import("./components/PaymentsComponent/PaymentsComponent"))
const Requests = React.lazy(() => import("./components/RequestComponent/Requests"))
const UserCommonBackground = React.lazy(() => import("./components/UserCommonBackground/UserCommonBackground"))
const Home = React.lazy(() => import("./HomeComponent/Home"))
const BankDetails = React.lazy(() => import("./pages/BankDetails/BankDetails"))
const BuyerPlatformSetup = React.lazy(() => import("./pages/BuyerPlatformSetup/BuyerPlatformSetup"))
const CompanyDetails = React.lazy(() => import("./pages/CompanyDetails/CompanyDetails"))
const CreateRequest = React.lazy(() => import("./pages/CreateRequest/CreateRequest"))
const EditAccount = React.lazy(() => import("./pages/EditAccount/EditAccount"))
const UserProfile = React.lazy(() => import("./pages/UserProfile/UserProfile"))
const Document = React.lazy(() => import("./pages/Document/Document"))
const Remittance = React.lazy(() => import("./components/viewRemittance/viewRemittance"))
const InvoiceVerify = React.lazy(() => import("./pages/InvoiceVerify/InvoiceVerify"));
const Dashboard = React.lazy(() => import("./components/Dashboard/dashboard"));

const ParentContainerComponent = ({ socket }) => {
  let id = window.localStorage.getItem("_id");
  if (window.localStorage.getItem("_id")) {
    id = window.localStorage.getItem("_id");
  }
  return (

    <div className="App" >
      <Router>
        <React.Suspense fallback={<p>Loading...</p>}>
          <Switch>
            {/* <Route exact path="/user" render={() => <Home />} /> */}
            

            <Route exact path="/user/tnc" component={UserCommonBackground} />
            <Route exact path="/user/SelectBuyerPlatform" component={UserCommonBackground} />
            <Route exact path="/user/payments" component={props => <PaymentsComponent socket={socket} />} />
            <Route exact path="/user/requests" component={props => <Requests socket={socket} />} />
            <Route exact path="/user/request" component={props => <Requests {...props} />} />
            <Route exact path="/user/bankDetails" component={BankDetails} />
            <Route exact path="/user/setupbuyerplatform/:selectedBP" component={BuyerPlatformSetup} />
            <Route exact path="/user/companyDetails" component={props => <CompanyDetails />} />
            <Route exact path="/user/createRequest" component={props => <CreateRequest socket={socket} />} />
            <Route exact path="/user/documents" component={Document} />

            <Route exact path="/userProfile" component={UserProfile} />
            <Route exact path="/userProfile/account" component={EditAccount} />
            <Route exact path="/userProfile/companyDetail" component={CompanyDetails} />
            <Route exact path="/userProfile/bankDetail" component={BankDetails} />
            <Route exact path="/userProfile/documents" component={Document} />
            <Route exact path="/userProfile/setUpBuyerPlatform/:selectedBP" component={BuyerPlatformSetup} />
            <Route exact path="/user/Remittance" component={props => <Remittance socket={socket} />} />
            <Route exact path="/dashboard" component={Dashboard} />
            


            {/* <Route path='*' exact component={Home} /> */}
            <Redirect to="/user" />
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  )

}
// class ParentContainerComponent extends Component {
//   id = window.localStorage.getItem("_id");

//   componentDidMount() {


//   }

//   render() {
//     if (window.localStorage.getItem("_id")) {
//       this.id = window.localStorage.getItem("_id");
//       //console.log("_id", this.id)
//     }
//     //alert(this.id)
//     return (
//       <div className="App" >
//         <Router>
//           <React.Suspense fallback={<Loader
//             type="Puff"
//             color="#00BFFF"
//             height={100}
//             width={100}
//             timeout={3000} //3 secs
//           />}>
//             <Switch>
//               {
//                 this.id != null ?
//                   ["/user", "/"].map(path => (
//                     <Route
//                       key={path}
//                       exact
//                       path={path}
//                       render={() => <Home id={this.id} />}
//                     />
//                   ))

//                   :
//                   [
//                     "/user",
//                     "/user/createAccount/:extraData?",
//                     "/:extraData?"
//                   ]
//                     .map(path => (
//                       <Route
//                         key={path}
//                         exact
//                         path={path}
//                         render={() => <UserCommonBackground />}
//                       />
//                     ))
//               }

//               <Route path="/user/tnc" component={UserCommonBackground} />
//               <Route path="/user/SelectBuyerPlatform" component={UserCommonBackground} />
//               <Route path="/user/payments" component={PaymentsComponent} />
//               <Route path="/user/requests" component={Requests} />
//               <Route path="/user/request" component={props => <Requests {...props} />} />
//               <Route path="/user/bankDetails" component={BankDetails} />
//               <Route path="/user/setupbuyerplatform/:selectedBP" component={BuyerPlatformSetup} />
//               <Route path="/user/companyDetails" component={CompanyDetails} />
//               <Route path="/user/createRequest" component={CreateRequest} />
//               <Route path="/user/documents" component={Document} />

//               <Route exact path="/userProfile" component={UserProfile} />
//               <Route path="/userProfile/account" component={EditAccount} />
//               <Route path="/userProfile/companyDetail" component={CompanyDetails} />
//               <Route path="/userProfile/bankDetail" component={BankDetails} />
//               <Route path="/userProfile/documents" component={Document} />
//               <Route path="/userProfile/setUpBuyerPlatform/:selectedBP" component={BuyerPlatformSetup} />
//               <Route path="/user/Remittance" component={Remittance} />

//               {/* <Route path='*' exact component={Home} /> */}
//               <Redirect to="/user" />
//             </Switch>
//           </React.Suspense>
//         </Router>
//       </div>
//     )
//   }

// }

export default ParentContainerComponent;