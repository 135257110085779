import React from 'react';
import Header from './Header/Header';
import SideBar from './Sidebar/SideBar';

const Layout = ({socket}) => {
    return(
        <div style={{paddingTop:0}}>     
            <SideBar />  
            <Header socket={socket} />          
        </div>

    )
}

export default Layout;