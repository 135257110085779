import t from "./types";
import { Api } from '../../services/httpService';

export const getMerchants = () => async (dispatch) => {
    const res = await Api('get', `/merchant`);
    if (res)
        await dispatch({ type: t.FETCHED_ACTIVITY, payload: res });
}

export const getRemittanceData = (merchantId, buyerPlatformId) => async (dispatch) => {
    const res = await Api('get', `/payments/${merchantId}/${buyerPlatformId}`)
    //console.log("getRemittanceData-Action",res);
    if (res) {
        await dispatch({ type: t.GET_REMITTANCE, payload: res });
    }
}

export const getDashboardData = (merchantId, buyerPlatformId) => async (dispatch) => {
    // console.log("merchantId, buyerPlatformId", merchantId, buyerPlatformId);
    const res = await Api('get', `/dashboard/${merchantId}/${buyerPlatformId}`);
    if (res) {
        await dispatch({ type: t.FETCHED_DASHBOARD_ACTIVITY, payload: res });
    }
}

export const createRequest = (params) => async (dispatch) => {
    const res = await Api('post', '/traditionalrequests/entry', params)
    if (res) {
        //console.log('response', res)
        await dispatch({ type: t.CREATE_REQUEST, payload: res });
    }
}

export const getRemittanceDataByMonth = (merchantId, buyerPlatformId) => async (dispatch) => {
    const res = await Api('get', `/payments/${merchantId}/${buyerPlatformId}`)

    //console.log("getRemittanceData-Action",res);

    if (res) {
        await dispatch({ type: t.GET_REMITTANCE_BY_MONTH, payload: res });
    }
}

export const getNotifications = (merchantId) => async (dispatch) => {
    const res = await Api('get', `/notifications/${merchantId}`)

    if (res) {
        await dispatch({ type: t.GET_NOTIFICATIONS, payload: res })
    }
}

export const updateNotifications = (params) => async (dispatch) => {
    const res = await Api('post', `/notifications/entry/`, params)
    if (res) {
        await dispatch({ type: t.UPDATE_NOTIFICATIONS, payload: res })
    }
}

export const verifyEmail = (params) => async (dispatch) => {
    const res = await Api('post', `/users/verifyEmail`, params, true)
    if (res) {
        await dispatch({ type: t.VERIFY_EMAIL, payload: res })
    }
}

export const verifyOtp = (params) => async (dispatch) => {
    const res = await Api('post', `/users/verifyOTP`, params, true)
    if (res) {
        await dispatch({ type: t.VERIFY_OTP, payload: res })
    }
}

export const changPassword = (params) => async (dispatch) => {
    const res = await Api('post', `/users/changePswd`, params, true)
    if (res) {
        await dispatch({ type: t.CHANGE_PSWD, payload: res })
    }
}

export const getCmsPage = (id) => async (dispatch) => {
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: true });
    const res = await Api("get", `/staticValues/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_CMS_PAGE_SINGLE, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_CMS_PAGE_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: false });
};

export const getCmsPages = () => async (dispatch) => {
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: true });
    const res = await Api("get", `/staticValues`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_CMS_PAGE, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_CMS_PAGE_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: false });
};
