import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import TimeAgo from 'react-timeago';
import { MerchantActions } from "../../../actions";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import "./notification.css";


class Notifications extends Component {
    id = window.localStorage.getItem("_id");
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            invoicesList: [],
            funderReceivedAmount: 0,
            unReadNotifications: [],
            unReadNotificationsId: [],
            sortedData: []
        }
    }

    componentDidMount() {

        if (window.localStorage.getItem("_id")) {
            this.id = window.localStorage.getItem("_id");
        } else {
            window.location.reload();
        };
        if (this.props.data) {
            const filteredResult = this.props.data.filter((item) => {
                return item.flag === 0
            })
            const unReadIds = filteredResult.map((item) => {
                return item.id
            })
            this.setState({
                unReadNotifications: filteredResult,
                unReadNotificationsId: unReadIds
            })
        }
    }

    updateNotification = (id) => {
        const params = {
            "merchantId": this.id,
            "flag": 1,
            "id": id,
        }
        this.props.updateNotifications(params)
        this.props.getNotifications(this.id).then(() => {
            if (this.props.getAllNotificationsData) {
                const filteredResult = this.props.getAllNotificationsData.filter((item) => {
                    return item.flag === 0
                })
                this.setState({
                    unReadNotifications: filteredResult,
                })
            }
        })
    }

    markAsRead = () => {
        let params = {
            "merchantId": this.id,
            "flag": 1,
            "ids": this.state.unReadNotificationsId,
            "id": this.state.unReadNotificationsId[0]
        }
        if (this.state.unReadNotificationsId.length > 0) {
            this.props.updateNotifications(params).then(() => {
                this.props.getNotifications(this.id).then(() => {
                    if (this.props.getAllNotificationsData) {
                        const filteredResult = this.props.getAllNotificationsData.filter((item) => {
                            return item.flag === 0
                        })
                        this.setState({
                            unReadNotifications: filteredResult,
                        })
                    }
                })
            })
        }
        // this.props.updateNotifications(params);
    }


    render() {
        const { data } = this.props;
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal()}
                dialogClassName="modalRight"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0 notification-title" closeButton>Notifications({this.state.unReadNotifications ? this.state.unReadNotifications.length : ''})   <p className="cursor" onClick={this.markAsRead}>Mark as read</p></Modal.Header>
                <Modal.Body>
                    <Accordion className="notification-wrap cursor">
                        {data ? data.map((item, index) => (
                            <Card onClick={() => {
                                this.updateNotification(item.id)
                            }}>
                                <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                                    <div class="notification-item">
                                        <h5><TimeAgo date={item.creationTimestamp} /> <i class="arrow-down"></i></h5>
                                        <p >{item.flag == 0 ? <b> {item.title} </b> : item.title}</p>
                                    </div>
                                </Accordion.Toggle>

                                <Accordion.Collapse eventKey={index + 1}>
                                    <Card.Body>
                                        <p>{item.message}</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )) : ''
                        }
                    </Accordion>

                    {/* {
                        this.state.sortedData ? this.state.sortedData.map((item, index) => (
                            <div class="notification-item">
                                <h5><TimeAgo date={item.creationTimestamp} /> <i class="arrow-down"></i></h5>
                                <h6>{item.title}</h6>
                                <p className="" onClick={()=>{
                                    this.updateNotification(item.id)
                                }}>{item.flag===0 ? <strong> {item.message} </strong> : item.message }</p>
                            </div>
                        )) : ''
                    } */}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = ({ MerchantListReducer }) => {
    return {
        updateNotificationsData: MerchantListReducer.updateNotificationsData,
        getAllNotificationsData: MerchantListReducer.getNotifications
    }
}

const mapDispatchToProps = {
    updateNotifications: MerchantActions.updateNotifications,
    getNotifications: MerchantActions.getNotifications
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)


