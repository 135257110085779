export function validatePass(text) {
  //console.log(text);
  let reg = new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
  if (reg.test(text) === false) {
    //console.log("Password is Not Correct");
    //   this.setState({
    //     password: text,
    //     validatedPassword: false,
    //     emptyPass: false,
    //   });
    return false;
  } else {
    //   this.setState({
    //     password: text,
    //     validatedPassword: true,
    //     emptyPass: false,
    //   });
    //console.log("Password is Correct");
    return true;
  }
}

export function validate(text) {
  //console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  if (reg.test(text) === false) {
    //console.log("Email is Not Correct");
    //   this.setState({ email: text, validatedEmail: false, emptyEmail: false });
    return false;
  } else {
    //   this.setState({ email: text, validatedEmail: true, emptyEmail: false });
    //console.log("Email is Correct");
    return true;
  }
}

export function validatePhone(action, text) {
  if (action == "") {
    return true;
  }
  else {
    let reg = new RegExp("^[0-9]{9}$");
    return reg.test(text);
  }


}