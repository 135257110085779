export default {
    FETCHED_MERCHANT_BUYER_INVOICES: 'FETCHED_MERCHANT_BUYER_INVOICES',
    CREATE_INVOICE: 'CREATE_INVOICE',
    GET_INVOICES: 'GET_INVOICES',
    GET_ALL_INVOICES: 'GET_ALL_INVOICES',
    GET_PENDING_REQUESTS: 'GET_PENDING_REQUESTS',
    GET_PAYMENT_DETAILS: "GET_PAYMENT_DETAILS",
    GET_INVOICE_DETAILS: 'GET_INVOICE_DETAILS',
    GET_CREATEREQUESTECOMMERCE: 'GET_CREATEREQUESTECOMMERCE',
    VERIFY_INVOICE_OTP: 'VERIFY_INVOICE_OTP',
    MARK_INVOICE_VERIFY: 'MARK_INVOICE_VERIFY',
    MARK_INVOICE_REJECT: 'MARK_INVOICE_REJECT'

}