import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import "./styles.css";
import { url } from "../../constants";
import { validate, validatePass, validatePhone } from "./Validations";


export default class CreateAccountScreen extends Component {

  constructor(props) {
    super();
    if (!ValidatorForm.hasValidationRule("validatePass")) {
      ValidatorForm.addValidationRule("validatePass", (value) => {
        // const { formData } = this.state;
        return validatePass(value);
      });
    }
    if (!ValidatorForm.hasValidationRule("validate")) {
      ValidatorForm.addValidationRule("validate", (value) => {
        // const { formData } = this.state;
        // if (isNaN(value)) {
        return validate(value);
        // }
      });
    }
    if (!ValidatorForm.hasValidationRule("validatePhone")) {

      ValidatorForm.addValidationRule("validatePhone", (value) => {
        // const { formData } = this.state;
        // if (isNaN(value)) {
        return validatePhone("", value);
        // }
      });
    }
  }
  state = {
    formData: {
      name: "",
      phone: "",
      email: "",
      password: "",
      role: "MERCHANT",
    },
    submitted: false,
    isRevealPassword: false,
    errorMsgs: {
      PhoneNumErrorMsg: [
        "Please enter valid phone number",
        "Please enter valid phone number", "abc"],
      EmailErrorMsg: [
        "Please enter a valid email address",
        "Please enter a valid email address",
      ],
      PasswordErrMsg: [
        "Please enter a valid password",
        "Please enter a valid password"
      ]
    },
    phoneErrorText: null,
    emailErrorText: null,
    passwordErrorText: null,
    validator: {
      NameValidators: ['required'],
      PhoneValidators: ['required'],
      EmailValidators: ['required'],
      PasswordValidators: ['required']
    },
    isCreateAccountFailed: false,
    Email_Phone_same_Data_Errormsg: ""
  };



  handleChange = (event) => {
    //console.log("....",event.target.validator)
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData, phoneErrorText: null, emailErrorText: null, passwordErrorText: null });
  };

  handleSubmit = (event) => {

    event.preventDefault();
    if (validatePass(this.state.formData.password) && validatePhone("sub", this.state.formData.phone) &&
      validate(this.state.formData.email)) {

      let num = "00971" + this.state.formData.phone;
      //console.log(num);
      var dataRaw = {
        name: this.state.formData.name,
        email: this.state.formData.email,
        password: this.state.formData.password,
        phone: num,
        status: "UNVERIFIED",
      };
      //console.log("data raw", dataRaw)
      let self = this;
      axios({
        method: "post",
        url: url + "/merchant/",
        data: dataRaw,
        // headers: { "Content-Type": 'application/json' }
      })
        .then(function (response) {
          //console.log(".......",response);
          //console.log(response.status);
          const s = response.status;

          //console.log(s);
          const n = 200;
          //console.log(n);
          if (s === n) {
            localStorage.setItem("_token", "Bearer " + response.data.body.user.token);

            console.log(response);
            window.localStorage.setItem("_id", response.data.body.id);
            window.localStorage.setItem("_name", response.data.body.user.name);
            window.localStorage.setItem("_phone", response.data.body.user.phone);
            window.localStorage.setItem("_email", response.data.body.user.email);
            window.localStorage.setItem("_password", self.state.formData.password);

            window.location = "/user/tnc";
            //"/user/tnc/" +
            // response.data.body.id +
            // "/" +
            // response.data.body.user.name +
            // "/" +
            // response.data.body.user.phone +
            // "/" +
            // response.data.body.user.email +
            // "/" +
            // self.state.formData.password;
          } else alert("Invalid Credentials");
        })
        .then(function (result) {
          //console.log("++++",result)
        })
        .catch(function (error) {
          //console.log("error",error.response);
          // let data = error.response.data
          // self.setState({isCreateAccountFailed: !data.success,
          //   Email_Phone_same_Data_Errormsg: data.message})

          //alert("Invalid things");
        });

    }
    else {

      let phoneErrorText = validatePhone(this.state.formData.phone) ? null : "Please enter valid phone number";
      let emailErrorText = validate(this.state.formData.email) ? null : "Please enter valid email address";
      let passwordErrorText = validatePass(this.state.formData.password) ? null : "Please enter valid password";

      this.setState({
        ...this.state, phoneErrorText: phoneErrorText,
        emailErrorText: emailErrorText,
        passwordErrorText: passwordErrorText
      })

    }
  };

  togglePassword = (event) => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  componentWillUnmount() {


    if (ValidatorForm.hasValidationRule("validatePass")) {
      ValidatorForm.removeValidationRule("validatePass");
    }

    if (ValidatorForm.hasValidationRule("validate")) {
      ValidatorForm.removeValidationRule("validate");
    }

    if (ValidatorForm.hasValidationRule("validatePhone")) {

      ValidatorForm.removeValidationRule("validatePhone");
    }
  }

  render() {
    //const logo = require("../../assests/Funder.ai.png");
    //console.log(this.state  )

    return (
      <div className="_form">
        <label className="welcome">Create Account</label>
        <p className="CaPara">Let's Start with getting to know you better</p>
        <ValidatorForm
          className="mgtp"
          ref={(r) => (this.form = r)}
          onSubmit={this.handleSubmit}
        >
          <InputLabel className="inputLabel">Name</InputLabel>
          <TextValidator
            className="form-field "
            style={{ marginBottom: 30 }}
            type="text"
            onChange={this.handleChange}
            name="name"
            value={this.state.formData.name}
            validators={this.state.validator.NameValidators}
            InputProps={{ disableUnderline: true }}
            errorMessages={[
              "Please enter your full name",
              // "Please enter a valid email address",
            ]}
          />
          <InputLabel className="inputLabel">Phone Number</InputLabel>
          <Row style={this.state.phoneErrorText == null || this.state.phoneErrorText == '' ? { marginBottom: 0 } : { marginBottom: 5 }}>
            <div className="col-xl-2 col-lg-3 col-sm-2 col-4">
              <TextValidator
                className="form-field-phoneF"
                type="text"
                name="phone"
                value="+971"
                style={{ marginBottom: 0, marginTop: 0 }}
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <div className="col-xl-10 col-lg-9 col-sm-10 col-8">
              <TextValidator
                className="form-field-phoneS"
                style={{ marginBottom: 0, marginTop: 0 }}
                type="text"
                onChange={this.handleChange}
                name="phone"
                value={this.state.formData.phone}
                validators={['required']}
                InputProps={{ disableUnderline: true }}
                errorMessages={this.state.errorMsgs.PhoneNumErrorMsg}

              />
            </div>
          </Row>
          <p style={{ color: "red", fontWeight: "bold", fontSize: 12, margin: 0, marginLeft: 240 }}>{this.state.phoneErrorText}</p>

          <InputLabel className="inputLabel" style={{ marginTop: 30 }}>Email ID</InputLabel>
          <TextValidator
            className="form-field"
            style={{ marginTop: 10, marginBottom: 5 }}
            type="text"
            onChange={this.handleChange}
            name="email"
            value={this.state.formData.email}
            validators={this.state.validator.EmailValidators}
            InputProps={{ disableUnderline: true }}
            errorMessages={[
              "Please enter a valid email address",
              "Please enter a valid email address",
            ]}
          />
          <p style={{ color: "red", fontWeight: "bold", fontSize: 12, marginLeft: 115, marginTop: 0 }}>{this.state.emailErrorText}</p>
          <InputLabel className="inputLabel img " style={{ marginTop: 30 }}>Password (Create)</InputLabel>
          <div className="passWrapper">
            <TextValidator
              className="form-field"

              style={{ marginBottom: 1 }}
              type={this.state.isRevealPassword ? "text" : "password"}
              onChange={this.handleChange}
              name="password"
              value={this.state.formData.password}
              validators={this.state.validator.PasswordValidators}
              InputProps={{ disableUnderline: true }}
              errorMessages={[
                // "",
                "Please enter a valid password",
                "Please enter a valid password"
              ]}
            />
            <span className="showHide" onClick={this.togglePassword}>
              {this.state.isRevealPassword ? "Hide" : "Show"}
            </span>
          </div>
          <p style={{ color: "red", fontWeight: "bold", fontSize: 12, marginLeft: 115, marginTop: 8 }}>{this.state.passwordErrorText}</p>
          <InputLabel
            className="PasswordLabel"
            style={{
              fontWeight: 'normal',
              fontSize: '15px',
              lineHeight: '21px',
              marginTop: 10
            }}>Password must be atleast 6 characters. Must contain atleast 1 uppercase and atleast 1 numeric digit.</InputLabel>

          {
            this.state.isCreateAccountFailed ?
              <div className="loginFailedBox" style={{ marginTop: 20, marginBottom: '0.3vh' }}>
                <p className="LoginFailPara">{this.state.Email_Phone_same_Data_Errormsg}</p>
              </div>
              :
              null
          }
          <button
            className="CreateAccformLogin w-100"
            type="submit"
            title="Next"
            variant="contained"
            color="secondary"
            style={{ marginTop: 35, marginLeft: 115 }}
          >
            {(this.state.submitted && "Your form is submitted!") ||
              (!this.state.submitted && "Next")}
          </button>
        </ValidatorForm>
        <div className="already-label">
          <h6>Already a member? <a href="/">Login</a></h6>
        </div>
      </div>

    )
    // return (
    //   <div className="landRoot"  style={{height:window.innerHeight+90}}>
    //     <div className="form">
    //       <label className="welcome">      ount</label>
    //       <p className="CaPara">Let's Start with getting to know you better</p>
    //       <ValidatorForm
    //         className="mgtp"
    //         ref={(r) => (this.form = r)}
    //         onSubmit={this.handleSubmit}
    //       >
    //         <InputLabel className="inputLabel">Name</InputLabel>
    //         <TextValidator
    //           className="form-field"
    //           type="text"
    //           onChange={this.handleChange}
    //           name="name"
    //           value={this.state.formData.name}
    //           validators={this.state.validator.NameValidators}
    //           InputProps={{ disableUnderline: true }}
    //           errorMessages={[
    //             "Please enter your full name",
    //             // "Please enter a valid email address",
    //           ]}
    //         />
    //         <InputLabel className="inputLabel">Phone Number</InputLabel>
    //         <Row>
    //           <TextValidator
    //           className="form-field-phoneF"
    //           type="text"
    //           name="phone"
    //           value="+971"             
    //           InputProps={{ disableUnderline: true }}
    //         />
    //         <TextValidator
    //           className="form-field-phoneS"
    //           type="text"
    //           onChange={this.handleChange}
    //           name="phone"
    //           value={this.state.formData.phone}
    //           validators={['required']}
    //           InputProps={{ disableUnderline: true }}
    //           errorMessages={this.state.errorMsgs.PhoneNumErrorMsg}

    //         />
    //          <p style={{color:"red",fontWeight:"bold", fontSize:12,marginLeft:270,marginTop:0}}>{this.state.phoneErrorText}</p>
    //         </Row>

    //         <InputLabel className="inputLabel">Email ID</InputLabel>
    //         <TextValidator
    //           className="form-field"
    //           type="text"
    //           onChange={this.handleChange}
    //           name="email"
    //           value={this.state.formData.email}
    //           validators={this.state.validator.EmailValidators}
    //           InputProps={{ disableUnderline: true }}
    //           errorMessages={[
    //              "Please enter a valid email address",
    //              "Please enter a valid email address",
    //           ]}
    //         />
    //         <p style={{color:"red",fontWeight:"bold", fontSize:12,marginLeft:270,marginTop:0}}>{this.state.emailErrorText}</p>
    //         <InputLabel className="inputLabel">Password (Create)</InputLabel>
    //         <div className="passWrapper">
    //           <TextValidator
    //             className="form-field"
    //             type={this.state.isRevealPassword ? "text" : "password"}
    //             onChange={this.handleChange}
    //             name="password"
    //             value={this.state.formData.password}
    //             validators={this.state.validator.PasswordValidators}
    //             InputProps={{ disableUnderline: true }}
    //             errorMessages={[
    //               // "",
    //                "Please enter a valid password",
    //                "Please enter a valid password"
    //             ]}
    //           />
    //            <span className="showHide" onClick={this.togglePassword}>
    //             {this.state.isRevealPassword ? "Hide" : "Show"}
    //           </span>
    //         </div>
    //         <p style={{color:"red",fontWeight:"bold", fontSize:12,marginLeft:130,marginTop:0}}>{this.state.passwordErrorText}</p>
    //         <InputLabel 
    //         className="PasswordLabel"
    //         style={{
    //           fontWeight: 'normal',
    //           fontSize: '15px',
    //           lineHeight: '21px'
    //         }}>Password must be atleast of 6 characters.Must contain atleast 1 uppercase, or capital letter & atleast 1 numeric digit.</InputLabel>


    //           <button
    //             className="CreateAccformLogin"
    //             type="submit"
    //             title="Next"
    //             variant="contained"
    //             color="secondary"
    //            style={{marginTop:35,marginLeft:115}}
    //           >
    //             {(this.state.submitted && "Your form is submitted!") ||
    //               (!this.state.submitted && "Next")}
    //           </button>

    //       </ValidatorForm>
    //     </div>
    //     {/* <label className="logog">FUNDER.AI</label> */}
    //     <div className="logog">
    //       <img src={logo} style={{height:80,width:270}}/>
    //     </div>
    //     <div className="mt"></div>
    //     <div className="feature1">
    //       <div className="square"></div>
    //       <div className="inColumn">
    //         <p className="bigLine">Get instant cash for sales invoices</p>
    //         <p className="smallLine">
    //           All your unpaid voices turned into liquid cash within 48 hours
    //           with Funder.ai
    //         </p>
    //       </div>
    //     </div>
    //     <div className="feature1">
    //       <div className="square"></div>
    //       <div className="inColumn">
    //         <p className="bigLine">Get instant cash for sales invoices</p>
    //         <p className="smallLine">
    //           All your unpaid voices turned into liquid cash within 48 hours
    //           with Funder.ai
    //         </p>
    //       </div>
    //     </div>
    //     <div className="feature1">
    //       <div className="square"></div>
    //       <div className="inColumn">
    //         <p className="bigLine">Get instant cash for sales invoices</p>
    //         <p className="smallLine">
    //           All your unpaid voices turned into liquid cash within 48 hours
    //           with Funder.ai
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}
