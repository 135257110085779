import React, { Component } from "react";
import ReactDOM from "react-dom";
import Chart from "react-google-charts";

// const data = [
//     ["Task", "Hours per Day"],
//     ["Work", this.props.series[0]],
//     ["Eat", this.props.series[1]],
    
//   ];
//   const options = {
//     title: "My Daily Activities",
//     pieHole: 0.4,
//     is3D: false
//     };
// const options = {
//     pieHole: 0.5,
//     pieSliceTextStyle: {
//       color: 'transparent',
//     },
//     legend: 'none',
//     colors: ["#169E9A", "#ffffff"],
//     backgroundColor: 'transparent'
//   };
  
export default class DonutChat extends React.Component {
  render() {
    return (
        <div className="chart-wrap">
            <Chart
                chartType="PieChart"
                width="270px"
                height="270px"
                data={[
                    ["Task", "Hours per Day"],
                    ["Used (AED) ", (this.props.series[0] > 0 ? this.props.series[0] : 0)],
                    ["Available (AED) ", (this.props.series[1] > 0 ? this.props.series[1] : 0) ],
                    
                  ]}
                options={{
                    pieHole: 0.5,
                    pieSliceTextStyle: {
                      color: 'transparent',
                    },
                    legend: 'none',
                    colors: ["#169E9A", "#ffffff"],
                    backgroundColor: 'transparent'
                  }}
            />
      </div>
    );
  }
}
