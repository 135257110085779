import React, { Component } from "react";
import Layout from "../../HomeLayout/Layout";
import "./DashboardComponent.css";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { url, alterNateImage } from "../../constants.js";
import ReactTooltip from "react-tooltip";
import { MerchantActions, InvoicesActions } from "../../actions";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import NetReceiable from "../Modals/NetReceiable/NetReceiable";
import NetPayments from "../Modals/NetPayments/NetPayments";
import ReserveModal from "../Modals/reserveModal/ReserveModal";
import Loader from "react-loader-spinner";
import _ from "lodash";
import moment from "moment";
import DonutChart from "./DonutChart";
import ContentLoader, { Facebook } from "react-content-loader";
import { withRouter } from 'react-router-dom';
import BuyerPlatformModal from '../Modals/SelectBuyerPlatform/selectBuyerPlatfrom'
class DashboardComponent extends Component {
  info = require("../../assests/info.png");
  datebtn = require("../../assests/dateBtn.png");
  A_dateBtn = require("../../assests/A_dateBtn.png");
  Used_donut_color = require("../../assests/Used_donut_color.png");
  Available_donut_color = require("../../assests/Available_donut_color.png");
  id = window.localStorage.getItem("_id");

  // componentDidMount() {
  //   if (window.localStorage.getItem("_id")) {
  //     this.id = window.localStorage.getItem("_id");
  //   } else {
  //     window.location.reload();
  //   }
  //   let _self = this;
  //   let tokenHeader = "";
  //   if (localStorage.getItem("_token")) {
  //     tokenHeader = localStorage.getItem("_token");
  //   }
  //   axios
  //     .get(`${url}/merchant/${this.id}`, { headers: { "Authorization": tokenHeader } })
  //     .then((res) => {
  //       let buyerPlatforms = res.data.body.buyerPlatforms;
  //       let self = this;
  //       self.setState({ username: res.data.body.user.name });
  //       //adding id of buyer platforms who is verified so that in select BP section it cannot be unselected.
  //       let ver_BP_temp = [..._self.state.Verified_BP_ids];
  //       buyerPlatforms.map((item) => {
  //         if (item.status == "VERIFIED") {
  //           console.log("Item", item);
  //           ver_BP_temp.push(item.buyerPlatform.id);
  //         }
  //       });
  //       let isNonVerifiedBP = false;
  //       //load logo
  //       buyerPlatforms.map((item, index) => {
  //         if (item.status != "VERIFIED") {
  //           isNonVerifiedBP = true;
  //         }
  //         let buyerID = item.buyerPlatform.id;
  //         if (item.buyerPlatform.logo != null) {
  //           let tokenHeader = "";
  //           if (localStorage.getItem("_token")) {
  //             tokenHeader = localStorage.getItem("_token");
  //           }
  //           var requestOptions = {
  //             method: "GET",
  //             redirect: "follow",
  //             headers: { "Authorization": tokenHeader },
  //           };
  //           fetch(`${url}/files/${item.buyerPlatform.logo.id}`, requestOptions)
  //             .then((response) => response.blob())
  //             .then((result) => {
  //               var reader = new FileReader();
  //               reader.readAsDataURL(result);
  //               reader.onloadend = function () {
  //                 var base64data = reader.result;

  //                 let temp = { ...self.state.logoIcon };
  //                 temp[buyerID] = base64data;
  //                 self.setState({ ...self.state, logoIcon: temp }, () => {
  //                   setTimeout(() => {
  //                     self.setState({ buyerPlatformImageLoading: false });
  //                   }, 100);
  //                 });
  //               };
  //             })
  //             .catch((error) => console.log("logo Fetch error", error));
  //         }
  //       });

  //       this.setState({
  //         ...this.state,
  //         buyerPlatforms: res.data.body.buyerPlatforms,
  //         isNonVerifiedBuyerPlatform: isNonVerifiedBP,
  //         Verified_BP_ids: ver_BP_temp,
  //       });
  //       if (localStorage.getItem("@buyerPlatformName") && localStorage.getItem("@selectBuyerPlatForm") && localStorage.getItem("@buyerPlatformType")) {
  //         this.onSelectBuyerPlatformHandler(
  //           localStorage.getItem("@buyerPlatformName"),
  //           localStorage.getItem("@selectBuyerPlatForm"),
  //           localStorage.getItem("@buyerPlatformType")
  //         );
  //       } else {
  //         if (res.data.body.buyerPlatforms.length > 0) {
  //           this.onSelectBuyerPlatformHandler(
  //             res.data.body.buyerPlatforms[0].buyerPlatform.name,
  //             res.data.body.buyerPlatforms[0].buyerPlatform.id,
  //             res.data.body.buyerPlatforms[0].buyerPlatform.typeDescription
  //           )
  //         }
  //       }
  //       // })
  //     })
  //     .catch((err) => console.log(err));
  // }

  state = {
    username: "",
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        show: false,
      },
      labels: ["Used", "Available"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      colors: ["#169E9A", "#ffffff"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
    },
    series: [0, 0],

    ActivelogoIndex: -1,
    ActiveBuyerLogoIndex: -1,
    BuyerPlatformWalletData: {
      totalSales: 0,
      eligibleAmount: 0,
      available_AED: 0,
      used_AED: 0,
      advanceAmount: 0,
      pendingAmount: 0,
    },
    month: [
      {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      },
    ],
    total: 0,
    percentage: 0,

    isBuyerPlatformSelected: false,
    selectBuyerPlatForm: "",
    totalAdvance: "50,000",
    totalPending: "10,000",
    netReceivable: false,
    nextPayment: false,
    reserveModal: false,
    factoringLimit: {
      available_AED: 120000,
      used_AED: 45000,
    },
    buyerPlatforms: [],
    isNonVerifiedBuyerPlatform: false,
    logoIcon: {},
    boolVal: {
      isTotalSalesSec: false,
      isEligibleAmountSec: false,
      isTotalAmountSec: false,
      isTotalPendingSec: false,
    },

    allBuyerPlatforms: [],
    activeBuyerPlatforms: [],
    isActive: false,
    AllBP_Logos: {},
    Verified_BP_ids: [],
    data: [],
    loader: false,
    buyerPlatformName: "",
    buyerPlatformType: "",
    endpoint: "http://127.0.0.1:4001",
    buyerPlatformImageLoading: true,
    buyerPlatformModal: false
  };
  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  //for bar chart

  calculatePercentage = async () => {
    let data = [];

    const total = this.props.dashboardData
      ? this.props.dashboardData.dashboard.availableBalance +
      this.props.dashboardData.dashboard.usedBalance
      : "";

    if (
      this.props.dashboardData &&
      (this.props.dashboardData.dashboard.usedBalance != 0 ||
        this.props.dashboardData.dashboard.availableBalance != 0)
    ) {
      data.push(
        parseInt(
          this.props.dashboardData
            ? this.props.dashboardData.dashboard.usedBalance.toFixed(0)
            : ""
        )
      );
      data.push(
        parseInt(
          this.props.dashboardData
            ? this.props.dashboardData.dashboard.availableBalance.toFixed(0)
            : ""
        )
      );
    } else {
      data.push(0);
    }
    this.setState({
      series: data,
    });
  };
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  onSelectBuyerPlatformHandler = async (
    selectedBuyerName,
    selectedBuyerId,
    selectedBuyerType
  ) => {
    this.setState({
      selectBuyerPlatForm: selectedBuyerId,
      buyerPlatformName: selectedBuyerName,
      buyerPlatformType: selectedBuyerType,
    });
    localStorage.setItem("@selectBuyerPlatForm", selectedBuyerId);
    localStorage.setItem("@buyerPlatformName", selectedBuyerName);
    localStorage.setItem("@buyerPlatformType", selectedBuyerType);

    let self = this;
    this.props.getDashboardData(this.id, selectedBuyerId).then(() => {
      this.calculatePercentage();
      this.setState({
        loader: false,
      });
    });
    if (this.props.socket) {
      this.props.socket.on("dashboardUpdate", () => {
        this.props.getDashboardData(this.id, selectedBuyerId).then(() => {
          this.calculatePercentage();
          this.setState({
            loader: false,
          });
        });
      });
    }
    this.props.getDataByMonth(this.id, selectedBuyerId);
    this.props.getAllInvoicesData(this.id, selectedBuyerId);
    var x = document.getElementById("BuyerPlatformGraphDatainDashboard");
    if (x) {
      if (x.style.display === "none") {
        x.style.display = "block";
      }
    };
  };

  onCloseCard = () => {
    document.getElementById("card_3").style.width = 0;
  };

  onCreateRequestHandler = () => {
    window.location = `/user/createrequest`;
  };

  onHandlerAddBuyerPlatform = () => {
    window.location = `/user/BuyerPlatforms`;
  };

  OpenSelectBuyerPlatform_SidePanel_Handler = () => {
    this.setState({
      buyerPlatformModal: true
    })
  };

  CloseSelectBuyerPlatform_SidePanel_Handler = () => {
    document.getElementById("SBP").style.width = "0px";
  };

  Select_BP_Handler = (index) => {
    let ans = false;
    const tmpState = this.state.activeBuyerPlatforms.map((val, tmpIndex) => {
      if (tmpIndex === index) {
        let isThisBP_verified = false;
        this.state.Verified_BP_ids.map((item) => {
          if (index == item) {
            isThisBP_verified = true;
          }
        });
        ans = ans || !val;
        if (isThisBP_verified) {
          return val;
        } else {
          return !val;
        }
      } else {
        ans = ans || val;
        return val;
      }
    });
    this.setState({
      ...this.state,
      activeBuyerPlatforms: tmpState,
      isActive: ans,
    });
  };

  OnDoneSelecting_BP_Handler = () => {
    let self = this;
    let bpIds = [];
    this.state.activeBuyerPlatforms.map((val, index) => {
      if (val === true) {
        bpIds.push(index.toString());
      }
    });

    let Ids = "";
    bpIds.map((val, index) => {
      Ids = Ids + val + ",";
    });
    Ids = Ids.slice(0, -1);
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    axios({
      method: "put",
      url: url + "/merchantBuyerPlatforms/" + "?bp_id=" + Ids + "&m_id=" + self.id,
      headers: { "Authorization": tokenHeader },
    })
      .then(function (response) {
        const s = response.status;
        const n = 200;
        if (s === n) {
          self.setState({
            ...self.state,
            buyerPlatforms: response.data.body.buyerPlatforms,
          });
          self.componentDidMount();
          document.getElementById("SBP").style.width = "0px";
        } else alert("Invalid Credentials");
      })
      .catch(function (error) {
        alert("Invalid things..");
      });
  };

  getNetReceiable = (data) => {
    this.setState({
      netReceivable: data,
    });
  };

  getNetPayments = (data) => {
    this.setState({
      nextPayment: data,
    });
  };
  getReserveModal = (data) => {
    this.setState({
      reserveModal: data,
    });
  };

  convertIntoNumberFormat = (amount) => {
    return (
      <NumberFormat
        value={amount}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  };
  closeModal = () => {
    this.setState({
      buyerPlatformModal: false
    })
  }
  MyLoader = () => (
    <ContentLoader>
      <rect x="2" y="2" rx="5" ry="5" width="42" height="42" />
    </ContentLoader>
  );

  render() {
    // console.log("Buyerplatform = >", this.state);
    return (
      <div>
        <Layout socket={this.props.socket} />

        <div
          id="container"
          style={{
            paddingLeft: 50,
            paddingTop: 65,
            paddingRight: 25,
            paddingBottom: 65,
          }}
        >
          <div className="row ml-2">
            <div className="col-md-9">
              {this.state.loader ? (
                <Loader
                  type="Puff"
                  color="#169E9A"
                  height={100}
                  width="100%"
                  timeout={3000} //3 secs
                />
              ) : (
                ""
              )}
              <h1 className="welcome_title mt-lg-3">Welcome {this.state.username}</h1>
            </div>
            <div className="col-md-3">
              <button
                className="createReqBtn cursor "
                style={{ marginLeft: 65 }}
                onClick={() => {
                  window.location = `/user/createRequest`;
                }}
              >
                + Create Request
              </button>
            </div>
          </div>

          {this.state.isNonVerifiedBuyerPlatform ? (
            <div className="row ml-2">
              <div className="col-sm-11">
                <p className="D_label  mt-xl-5 mt-4">
                  Please complete your pending tasks
                </p>

                <div className="row ">
                  <div
                    id={
                      this.state.buyerPlatforms.length > 3
                        ? "Dashboardwrapper"
                        : "nowrapper"
                    }
                  >
                    {this.state.buyerPlatforms.map((item, index) =>
                      item.status != "VERIFIED" ? (
                        <div
                          key={index}
                          style={{ width: 340, border: "1px solid white" }}
                        >
                          <div
                            className="D_card cursor"
                            style={{ marginRight: 33 }}
                            id="card_0"
                            onClick={() => {
                              this.props.history.push({
                                pathname: `/user/setupbuyerplatform/${item.buyerPlatform.id}`, state: {
                                  name: item.status
                                }
                              })
                            }}
                          >
                            <div className="row">
                              <p
                                className="col-sm-6 D_label"
                                style={{ fontSize: 20 }}
                              >
                                {item.buyerPlatform.name}
                              </p>
                            </div>
                            <div className="row">
                              <p className="col-sm-9 CR_card_text">
                                {item.buyerPlatform.typeDescription}
                              </p>
                            </div>
                            <div className="row">
                              <p
                                className="col-sm-11 text-right CR_card_status"
                                style={
                                  item.status == "VERIFICATION_PENDING"
                                    ? { color: "#DBAA55" }
                                    : { color: "var(--red)" }
                                }
                              >
                                {item.status == "VERIFICATION_PENDING"
                                  ? "Verification Pending"
                                  : item.status == "INCOMPLETE"
                                    ? "Incomplete"
                                    : null}{" "}
                                &nbsp;
                                <span>
                                  {"  "}
                                  <FontAwesomeIcon icon={faPlay} />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row ml-2 mt-4">
            <div className="col-sm-11">
              <p className="D_label">Select Buyer Platform</p>

              <div className="row mt-3">
                {this.state.buyerPlatforms.map((item, index) =>
                  item.status === "VERIFIED" && item.active === true ? (
                    <div
                      className="col-sm-auto cursor "
                      style={{ padding: "0px 10px" }}
                      onClick={() => {
                        this.onSelectBuyerPlatformHandler(
                          item.buyerPlatform.name,
                          item.buyerPlatform.id,
                          item.buyerPlatform.typeDescription
                        );
                      }}
                    >
                      <div
                        className="CR_buyerPlatform_Icon_container"
                        style={
                          this.state.selectBuyerPlatForm ==
                            item.buyerPlatform.id
                            ? {
                              border: "1.5px solid #169E9A",
                              alignItems: "center",
                              justifyContent: "center",
                            }
                            : {
                              border: "1px solid var(--border-9d8)",
                              alignItems: "center",
                              justifyContent: "center",
                            }
                        }
                      >
                        {_.isEmpty(
                          this.state.logoIcon[item.buyerPlatform.id]
                        ) ? (
                          this.state.buyerPlatformImageLoading ? (
                            this.MyLoader()
                          ) : (
                            <img
                              src={alterNateImage}
                              style={{ width: 28, height: 28, marginTop: 8 }}
                            />
                          )
                        ) : this.state.buyerPlatformImageLoading ? (
                          this.MyLoader()
                        ) : (
                          <img
                            src={this.state.logoIcon[item.buyerPlatform.id]}
                            style={{ width: 28, height: 28, marginTop: 8 }}
                          />
                        )}
                      </div>
                      <p
                        className="CR_buyerPlatform_icon_title"
                        style={
                          this.state.selectBuyerPlatForm ==
                            item.buyerPlatform.id
                            ? { fontWeight: "bold", color: "#169E9A" }
                            : { fontWeight: "normal", color: "var(--border-9d8)" }
                        }
                      >
                        {item.buyerPlatform.name}
                      </p>
                    </div>
                  ) : null
                )}

                <div
                  style={{ cursor: "pointer", marginLeft: 50 }}
                  onClick={() =>
                    this.OpenSelectBuyerPlatform_SidePanel_Handler()
                  }
                >
                  <div className="addBuyerPlatforminDashboard mt-1">
                    <p className="addSign">+</p>
                  </div>
                  <p className="addBuyerPlatforminDashboard_text">
                    Add Platform
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            id="BuyerPlatformGraphDatainDashboard"
            style={{ display: "none", marginLeft: 10 }}
          >
            <div className="row ml-md-2 mt-2">
              <div className="col-md-5 donetGraphSection">
                <div class="remittances cursor">
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      window.location = `/user/Remittance`;
                    }}
                  >
                    View Remittances
                  </a>
                </div>
                <div
                  className="row align-items-center"
                >
                  <div
                    className="col-sm-5 donutChart"
                  >
                    <DonutChart series={this.state.series} />
                  </div>
                  <div
                    className="col-sm-7 pl-md-5 pl-sm-3"
                  >
                    <div className="row  ">
                      <div className="col-sm-12">
                        <p className=" mb-4 D_label">Wallet balance</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1 col-2">
                        <img src={this.Available_donut_color} />
                      </div>
                      <p className="col-sm-5 col-6 subSecTitle mt-1 pl-2 pr-2">
                        Available (AED)
                      </p>
                      <p
                        className="col-sm-5 col-4 D_label mt-1 pl-2 pr-2"
                        style={{ fontSize: 18, fontWeight: "normal" }}
                      >
                        {this.props.dashboardData ? (
                          this.props.dashboardData.dashboard.availableBalance >
                            0 ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard
                                  .availableBalance
                                  ? this.props.dashboardData.dashboard.availableBalance.toFixed(
                                    0
                                  )
                                  : "0"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            0
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-sm-1 col-2">
                        <img src={this.Used_donut_color} />
                      </div>
                      <p className="col-sm-5 col-6 subSecTitle mt-1 pl-2 pr-2">
                        Used (AED)
                      </p>
                      <p
                        className="col-sm-5 col-4 D_label mt-1 pl-2 pr-2"
                        style={{ fontSize: 18, fontWeight: "normal" }}
                      >
                        {this.props.dashboardData ? (
                          <NumberFormat
                            value={
                              this.props.dashboardData.dashboard.usedBalance
                                ? this.props.dashboardData.dashboard.usedBalance.toFixed(
                                  0
                                )
                                : "0"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/*End of donut section */}

              {/* subSection */}
              <div className="col-md-7 mt-md-0 mt-4">
                <div className="row ml-0 dashboard-right">
                  {this.state.buyerPlatformType === "Working Capital" ? (
                    ""
                  ) : (
                    <div
                      onClick={() => {
                        this.state.buyerPlatformType === "Traditional"
                          ? this.setState({ netReceivable: true })
                          : this.setState({ netReceivable: false });
                      }}
                      className={
                        this.state.boolVal.isTotalSalesSec
                          ? "col-sm-3 px-2 mb-4 A_subSection cursor"
                          : "col-sm-3 px-2 mb-4 cursor"
                      }
                    >
                      <div className="subSection subSection-two">
                        <a>
                          <div
                            className="col-sm-12 subSecTitle"
                            style={
                              (this.state.boolVal.isTotalSalesSec
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" },
                              {
                                color: "#757373",
                                padding: "20px 20px",
                                fontWeight: "bold",
                                paddingBottom: 14,
                              })
                            }
                          >
                            Net Receivables (AED) &nbsp;
                            <span style={{ float: "right" }}>
                              <img
                                src={this.info}
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="netReceivable"
                              />
                            </span>
                          </div>
                          <ReactTooltip id="netReceivable" place="top">
                            Total Amt. of Receivabel from approved invoices or
                            payments
                          </ReactTooltip>

                          <div className="totalAmount">
                            {this.props.dashboardData ? (
                              <NumberFormat
                                value={
                                  this.props.dashboardData.dashboard
                                    .netReceivable
                                    ? this.props.dashboardData.dashboard.netReceivable.toFixed(
                                      0
                                    )
                                    : "-"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            ) : (
                              ""
                            )}
                          </div>

                        </a>
                      </div>
                    </div>
                  )}
                  {this.state.buyerPlatformType === "Working Capital" ? (
                    <div
                      onClick={() => {
                        this.state.buyerPlatformType === "Traditional"
                          ? this.setState({ nextPayment: true })
                          : this.setState({ nextPayment: false });
                      }}
                      className={
                        this.state.boolVal.isEligibleAmountSec
                          ? "col-sm-3 px-2 mb-4 A_subSection cursor"
                          : "col-sm-3 px-2 mb-4 cursor"
                      }
                    >
                      <div className="subSection subSection-two">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isEligibleAmountSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: " 20px 20px",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Next Settlement Date &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="Next Payment"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="Next Payment" place="top">
                          Next settlement date of {" "}
                          {this.state.buyerPlatformName}
                        </ReactTooltip>
                        <div className="totalAmount">

                          {this.props.dashboardData &&
                            this.props.dashboardData.dashboard.nextPaymentDate
                            ? moment(
                              this.props.dashboardData.dashboard
                                .nextPaymentDate
                            ).format("ll")
                            : ""}

                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        this.state.buyerPlatformType === "Traditional"
                          ? this.setState({ nextPayment: true })
                          : this.setState({ nextPayment: false });
                      }}
                      className={
                        this.state.boolVal.isEligibleAmountSec
                          ? "col-sm-3 px-2 mb-4 A_subSection cursor"
                          : "col-sm-3 px-2 mb-4 cursor"
                      }
                    >
                      <div className="subSection subSection-two">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isEligibleAmountSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: " 20px 20px",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Next Payments &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="Next Payment"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="Next Payment" place="top">
                          Sum of upcoming payment amount from{" "}
                          {this.state.buyerPlatformName}
                        </ReactTooltip>
                        <div className="totalAmount">
                          {this.props.dashboardData ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard.nextPayment
                                  ? this.props.dashboardData.dashboard.nextPayment.toFixed(
                                    0
                                  )
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            ""
                          )}
                          <p>
                            {this.props.dashboardData &&
                              this.props.dashboardData.dashboard.nextPaymentDate
                              ? moment(
                                this.props.dashboardData.dashboard
                                  .nextPaymentDate
                              ).format("ll")
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={
                      this.state.boolVal.isTotalAmountSec
                        ? "col-sm-3 px-2 mb-4 A_subSection"
                        : "col-sm-3 px-2 mb-4"
                    }
                  >
                    <div className="subSection">
                      <div
                        className="col-sm-12 subSecTitle"
                        style={
                          (this.state.boolVal.isTotalAmountSec
                            ? { fontWeight: "bold" }
                            : { fontWeight: "normal" },
                          {
                            color: "#757373",
                            padding: " 20px 20px",
                            paddingBottom: 14,
                          })
                        }
                      >
                        Funder Recv.(AED) &nbsp;
                        <span style={{ float: "right" }}>
                          <img
                            src={this.info}
                            style={{ cursor: "pointer" }}
                            data-tip
                            data-for="Funder Receivable"
                          />
                        </span>
                      </div>
                      <ReactTooltip id="Funder Receivable" place="top">
                        Advances + pending dues + early payment changes +
                        overdue charges
                      </ReactTooltip>
                      <div className="totalAmount">
                        {this.props.dashboardData ? (
                          <NumberFormat
                            value={
                              this.props.dashboardData.dashboard
                                .funderReceivable
                                ? this.props.dashboardData.dashboard.funderReceivable.toFixed(
                                  0
                                )
                                : "-"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.boolVal.isTotalPendingSec
                        ? "col-sm-3 px-2 mb-4 A_subSection"
                        : "col-sm-3 px-2 mb-4"
                    }
                  >
                    <div className="subSection">
                      <div
                        className="col-sm-12 subSecTitle"
                        style={
                          (this.state.boolVal.isTotalPendingSec
                            ? { fontWeight: "bold" }
                            : { fontWeight: "normal" },
                          {
                            color: "#757373",
                            padding: " 20px 20px",
                            paddingBottom: 14,
                          })
                        }
                      >
                        Net Paid (AED) &nbsp;
                        <span style={{ float: "right" }}>
                          <img
                            src={this.info}
                            style={{ cursor: "pointer" }}
                            data-tip
                            data-for="Net Paid"
                          />
                        </span>
                      </div>
                      <ReactTooltip id="Net Paid" place="top">
                        Amount of advance provided adjusted for fees but not yet
                        received
                      </ReactTooltip>
                      <div className="totalAmount">
                        {this.props.dashboardData ? (
                          <NumberFormat
                            value={
                              this.props.dashboardData.dashboard.netPaid
                                ? this.props.dashboardData.dashboard.netPaid.toFixed(
                                  0
                                )
                                : "-"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {this.state.buyerPlatformType === "Working Capital" ? (
                    <div
                      className={
                        this.state.boolVal.isTotalSalesSec
                          ? "col-sm-3 px-2 mb-4 A_subSection"
                          : "col-sm-3 px-2 mb-4"
                      }
                    >
                      <div className="subSection">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isTotalSalesSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: "20px 20px",
                              fontWeight: "bold",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Facility Limit &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="TotalReceivablesTooltip"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="TotalReceivablesTooltip" place="top">
                          Total factoring limit - Used Limit
                        </ReactTooltip>

                        <div className="totalAmount">
                          {this.props.dashboardData ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard
                                  .availableFacilitylimit
                                  ? this.props.dashboardData.dashboard.availableFacilitylimit.toFixed(
                                    0
                                  )
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        this.state.boolVal.isTotalSalesSec
                          ? "col-sm-3 px-2 mb-4 A_subSection"
                          : "col-sm-3 px-2 mb-4"
                      }
                    >
                      <div className="subSection">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isTotalSalesSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: "20px 20px",
                              fontWeight: "bold",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Available Limit (AED) &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="TotalReceivablesTooltip"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="TotalReceivablesTooltip" place="top">
                          Total factoring limit - Used Limit
                        </ReactTooltip>

                        <div className="totalAmount">
                          {this.props.dashboardData ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard
                                  .availableFacilitylimit
                                  ? this.props.dashboardData.dashboard.availableFacilitylimit.toFixed(
                                    0
                                  )
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.buyerPlatformType === "Working Capital" ? (
                    ""
                  ) : (
                    <div
                      className={
                        this.state.boolVal.isEligibleAmountSec
                          ? "col-sm-3 px-2 mb-4 A_subSection"
                          : "col-sm-3 px-2 mb-4"
                      }
                    >
                      {this.state.buyerPlatformType === "Traditional" ? (
                        <div className="subSection">
                          <div
                            className="col-sm-12 subSecTitle"
                            style={
                              (this.state.boolVal.isEligibleAmountSec
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" },
                              {
                                color: "#757373",
                                padding: " 20px 20px",
                                paddingBottom: 14,
                              })
                            }
                          >
                            No. of Invoices 12 &nbsp;
                            <span style={{ float: "right" }}>
                              <img
                                src={this.info}
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="WalletBalanceTooltip"
                              />
                            </span>
                          </div>
                          <ReactTooltip id="WalletBalanceTooltip" place="top">
                            Number of invoices available for factoring
                          </ReactTooltip>
                          <div className="totalAmount">
                            {this.props.dashboardData ? (
                              <NumberFormat
                                value={
                                  this.props.dashboardData.dashboard
                                    .invoicesCount
                                    ? this.props.dashboardData.dashboard
                                      .invoicesCount
                                    : "-"
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : this.state.buyerPlatformType === "E-Commerce" ? (
                        <div className="subSection">
                          <div
                            className="col-sm-12 subSecTitle"
                            style={
                              (this.state.boolVal.isEligibleAmountSec
                                ? { fontWeight: "bold" }
                                : { fontWeight: "normal" },
                              {
                                color: "#757373",
                                padding: " 20px 20px",
                                paddingBottom: 14,
                              })
                            }
                          >
                            Receivable Period &nbsp;
                            <span style={{ float: "right" }}>
                              <img
                                src={this.info}
                                style={{ cursor: "pointer" }}
                                data-tip
                                data-for="WalletBalanceTooltip"
                              />
                            </span>
                          </div>
                          <ReactTooltip id="WalletBalanceTooltip" place="top">
                            Receivable Period
                          </ReactTooltip>
                          <div className="totalAmount">
                            {this.props.dashboardData &&
                              this.props.dashboardData.dashboard.receivablePeriod
                              ? this.props.dashboardData.dashboard
                                .receivablePeriod
                              : "-"}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  {this.state.buyerPlatformType === "Working Capital" ? (
                    ""
                  ) : (
                    <div
                      className={
                        this.state.boolVal.isTotalAmountSec
                          ? "col-sm-3 px-2 mb-4 A_subSection"
                          : "col-sm-3 px-2 mb-4"
                      }
                    >
                      <div className="subSection">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isTotalAmountSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: " 20px 20px",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Limit Utilization %age &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="TotalAdvanceTooltip"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="TotalAdvanceTooltip" place="top">
                          Utilized limit percentage
                        </ReactTooltip>
                        <div className="totalAmount">
                          {this.props.dashboardData ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard.netLimit
                                  ? this.props.dashboardData.dashboard.netLimit.toFixed(
                                    2
                                  )
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            ""
                          )}
                          %
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.buyerPlatformType === "Working Capital" ? (
                    <div
                      className={
                        this.state.boolVal.isTotalAmountSec
                          ? "col-sm-3 px-2 mb-4 A_subSection"
                          : "col-sm-3 px-2 mb-4"
                      }
                    >
                      <div className="subSection">
                        <div
                          className="col-sm-12 subSecTitle"
                          style={
                            (this.state.boolVal.isTotalAmountSec
                              ? { fontWeight: "bold" }
                              : { fontWeight: "normal" },
                            {
                              color: "#757373",
                              padding: " 20px 20px",
                              paddingBottom: 14,
                            })
                          }
                        >
                          Pending Amount(AED) &nbsp;
                          <span style={{ float: "right" }}>
                            <img
                              src={this.info}
                              style={{ cursor: "pointer" }}
                              data-tip
                              data-for="TotalAdvanceTooltip"
                            />
                          </span>
                        </div>
                        <ReactTooltip id="TotalAdvanceTooltip" place="top">
                          Pending Amount
                        </ReactTooltip>
                        <div className="totalAmount">
                          {this.props.dashboardData ? (
                            <NumberFormat
                              value={
                                this.props.dashboardData.dashboard.netReceivable
                                  ? this.props.dashboardData.dashboard
                                    .netReceivable
                                  : "-"
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    onClick={() => {
                      this.setState({ reserveModal: true });
                    }}
                    className={
                      this.state.boolVal.isTotalPendingSec
                        ? "col-sm-3 px-2 mb-4 A_subSection cursor"
                        : "col-sm-3 px-2 mb-4 cursor"
                    }
                  >
                    <div className="subSection subSection-two">
                      <div
                        className="col-sm-12 subSecTitle"
                        style={
                          (this.state.boolVal.isTotalPendingSec
                            ? { fontWeight: "bold" }
                            : { fontWeight: "normal" },
                          {
                            color: "#757373",
                            padding: " 20px 20px",
                            paddingBottom: 14,
                          })
                        }
                      >
                        Reserve Amount &nbsp;
                        <span style={{ float: "right" }}>
                          <img
                            src={this.info}
                            style={{ cursor: "pointer" }}
                            data-tip
                            data-for="FunderReceivablesTooltip"
                          />
                        </span>
                      </div>
                      <ReactTooltip id="FunderReceivablesTooltip" place="top">
                        Surplus amount received from{" "}
                        {this.state.buyerPlatformName} adjusted for any pending
                        amount
                      </ReactTooltip>
                      <div className="totalAmount">
                        {this.props.dashboardData ? (
                          <NumberFormat
                            value={
                              this.props.dashboardData.dashboard.reserveAmount
                                ? this.props.dashboardData.dashboard
                                  .reserveAmount
                                : "-"
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*End of Balance Boxes Section */}
          </div>

          {/* Select Buyer Platform side panel */}

          <div id="SBP" className="_SelectBuyerPlatform_SidePanel">
            <div className="row" style={{ marginLeft: 40, marginTop: 30 }}>
              <div className="col-sm-11">
                <div className="row ">
                  <div className="col-sm-11 SBP_title">
                    Select Buyer Platform
                  </div>
                  <div className="col-sm-1 text-right">
                    <span
                      className="closebtn mt-1 cursor"
                      onClick={() =>
                        this.CloseSelectBuyerPlatform_SidePanel_Handler()
                      }
                    >
                      <b>x</b>
                    </span>
                  </div>
                  <p
                    className="row mt-2 ml-3 font"
                    style={{ fontSize: 14, color: "var(--color-c6c)" }}
                  >
                    Who do you want to sell to via?
                  </p>
                </div>

                <div className="Thescroll" style={{ marginTop: 5 }}>
                  <div className="select_BP_Container row">
                    {this.state.allBuyerPlatforms.map((val, index) => (
                      <div className="col-sm-4 mt-3" key={val.id}>
                        <div
                          onClick={() => this.Select_BP_Handler(val.id)}
                          className={
                            this.state.activeBuyerPlatforms[val.id]
                              ? "imgButtonActive cursor"
                              : "imgButton cursor"
                          }
                        >
                          {this.state.AllBP_Logos[val.id] == null ? (
                            <img
                              src={alterNateImage}
                              style={{ width: 73, height: 72, marginTop: 0 }}
                            />
                          ) : (
                            <img
                              src={this.state.AllBP_Logos[val.id]}
                              style={{ width: 73, height: 72, marginTop: 0 }}
                            />
                          )}
                        </div>
                        <p
                          className={
                            this.state.activeBuyerPlatforms[val.id]
                              ? "buttonLabelActive"
                              : "buttonLabel"
                          }
                          style={{ marginRight: 40, marginTop: 8 }}
                        >
                          {val.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <button
                  disabled={!this.state.isActive}
                  className={
                    !this.state.isActive
                      ? "CreateAccformSelectBPDisabled cursor"
                      : "CreateAccformSelectBP cursor"
                  }
                  style={{ bottom: 50, marginLeft: 12 }}
                  onClick={() => this.OnDoneSelecting_BP_Handler()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          {/* End of Select Buyer Platform side panel */}
        </div>

        {/* Use Models  */}
        {this.state.netReceivable && (
          <NetReceiable
            openModal={this.state.netReceivable}
            closeModal={this.getNetReceiable}
            merchantId={this.id}
            buyerPlatformId={this.state.selectBuyerPlatForm}
            socket={this.props.socket}
          />
        )}

        {this.state.nextPayment && (
          <NetPayments
            openModal={this.state.nextPayment}
            closeModal={this.getNetPayments}
            merchantId={this.id}
            buyerPlatformId={this.state.selectBuyerPlatForm}
            socket={this.props.socket}
          />
        )}
        {this.state.reserveModal && (
          <ReserveModal
            openModal={this.state.reserveModal}
            closeModal={this.getReserveModal}
            merchantId={this.id}
            buyerPlatformId={this.state.selectBuyerPlatForm}
            socket={this.props.socket}
          />
        )}
        {this.state.buyerPlatformModal && (
          <BuyerPlatformModal
            openModal={this.state.buyerPlatformModal}
            userId={this.id}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ MerchantListReducer, InvoiceReducer }) => {
  return {
    dashboardData: MerchantListReducer.dashboardData,
    getRemittanceByMonth: MerchantListReducer.getRemittanceByMonth,
    netPaymentDate: InvoiceReducer.netPayment,
  };
};

const mapDispatchToProps = {
  getDashboardData: MerchantActions.getDashboardData,
  getDataByMonth: MerchantActions.getRemittanceDataByMonth,
  getAllInvoicesData: InvoicesActions.getAllInvoicesData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardComponent))
