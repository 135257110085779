import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import "./styles.css";
import { url } from "../../constants";
import { validate, validatePass, validatePhone } from "../CreateAccountScreen/Validations";
import toast, { Toaster } from 'react-hot-toast';
import _, { isEqual } from 'lodash';
import { connect } from "react-redux";
import { MerchantActions, UserProfileActions } from "../../actions";
import { withRouter } from "react-router";

class LandingScreen extends Component {
  constructor(props) {
    super();
    if (!ValidatorForm.hasValidationRule("validatePass")) {
      ValidatorForm.addValidationRule("validatePass", (value) => {

        return validatePass(value);
      });
    }
    if (!ValidatorForm.hasValidationRule("validate")) {
      ValidatorForm.addValidationRule("validate", (value) => {

        return validate(value);
      });
    }
  }
  state = {
    formData: {
      email: "",
      password: "",
      role: "MERCHANT",
      otp: '',
      newPassword: '',
      confirmPassword: ''
    },
    submitted: false,
    submittedForgetPassword: false,
    isRevealPassword: false,
    isRevealPasswordForConfirm: false,
    loginFail: false,
    emailErrorText: null,
    passwordErrorText: null,
    loginErrorText: null,
    forgetPassword: false,
    otpField: false,
    otpVerified: false,
    passwordCreated: false,
    errorMessage: '',
    emailData: {},
    spinner: false,
    loginLoader: false
  };

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;

    this.setState({ formData, emailErrorText: null, passwordErrorText: null, loginErrorText: null });
  };

  // handleSubmit = (event)=>{
  //   this.props.history.push('/')
  // }
  handleSubmit = (event) => {
    this.setState({ loginLoader: true })
    event.preventDefault();
    if (validatePass(this.state.formData.password)
      && validate(this.state.formData.email)) {
      this.setState({ loading: true });
      var bodyFormData = new FormData();
      bodyFormData.append("email", this.state.formData.email);
      bodyFormData.append("password", this.state.formData.password);
      let login = false;
      let self = this;
      axios({
        method: "post",
        url: url + "/users/login",
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          const s = response.status;
          console.log(response);
          const n = 202;
          console.log(n);
          if (s === n) {
            if (response.data.roles[0] === 'INVESTOR') {
              localStorage.setItem("_token", "Bearer " + response.data.token);
              localStorage.setItem("_id", response.data.id);

              self.setState({ loginLoader: false });
              this.props.getUserProfile(response.data.id).then(() => {

                console.log("this.props.userProfile", this.props.userProfile);
                // debugger;
                localStorage.setItem("_name", this.props.userProfile.name);
                this.props.history.push('/')
              });
              //
            } else {
              self.setState({
                loginFail: true,
                loading: false,
                loginLoader: false,
                loginErrorText: "You don't have permission to access this portal"
              })
            }
          } else {
            self.setState({
              loginFail: true,
              loading: false,
              errorMessage: 'Invalid Login'
            })
          }
        })
        .catch(function (response) {
          //  alert("Invalid Credentials");
          self.setState({
            loginLoader: false,
            loginFail: true,
            loginErrorText: "Login Failed! Please check the details you've entered",
            loading: false
          })
          console.log("__", response);
        });

    } else {
      let emailErrorText = validate(this.state.formData.email) ? null : "Please enter valid email address";
      let passwordErrorText = validatePass(this.state.formData.password) ? null : "Please enter valid password";

      this.setState({
        ...this.state,
        emailErrorText: emailErrorText,
        passwordErrorText: passwordErrorText,
        loginLoader: false
      })
    }
  };

  amazonClicked() {
    console.log("amazonClicked");
  }
  togglePassword = (event) => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  componentWillUnmount() {

    if (ValidatorForm.hasValidationRule("validatePass")) {
      ValidatorForm.removeValidationRule("validatePass");
    }

    if (ValidatorForm.hasValidationRule("validate")) {
      ValidatorForm.removeValidationRule("validate");
    }
  }

  render() {
    const { formData, submitted, submittedForgetPassword } = this.state;

    return this.state.spinner ? (
      <div>
        <Spinner animation="border"></Spinner>
      </div>
    ) : (
      <div className="_form">
        <label className="welcome">Welcome</label>

        <ValidatorForm
          //  className="mgtop"
          ref={(r) => (this.form = r)}
          onSubmit={this.handleSubmit}
        >
          <div> <InputLabel className="inputLabel">Email ID</InputLabel>
            <TextValidator
              className="form-field"
              type="text"
              onChange={this.handleChange}
              name="email"
              value={formData.email}
              validators={["required"]}
              InputProps={{ disableUnderline: true }}
              errorMessages={[
                "Please enter email address",
                // "Please enter a valid email address",
              ]}
            />
            <p style={{ color: "red", fontWeight: "bold", fontSize: 12, textAlign: "center", marginTop: 0 }}>{this.state.emailErrorText}</p> </div>
          <div> <InputLabel className="inputLabel lmg">Password</InputLabel>
            <div className="passWrapper">
              <TextValidator
                className="form-field"
                type={this.state.isRevealPassword ? "text" : "password"}
                onChange={this.handleChange}
                name="password"
                value={formData.password}
                validators={["required"]}
                InputProps={{ disableUnderline: true }}
                errorMessages={[
                  "Please enter password",
                  // "Please enter a valid email address",
                ]}
              />

              <span className="showHide cursor" onClick={this.togglePassword}>
                {this.state.isRevealPassword ? "Hide" : "Show"}
              </span>

            </div> </div><br></br>
          {this.state.loginErrorText ? <p style={{ color: 'red' }}>{this.state.loginErrorText}</p> : ''}
          <Row>
            <button
              className="formLogin"
              type="submit"
              title="Login"
              variant="contained"
              color="secondary"
            >Login
            </button>
          </Row>
        </ValidatorForm>

        <Toaster position="top-center"
          reverseOrder={false} />
      </div>

    )
  }
}

const mapStateToProps = ({ MerchantListReducer, userProfileReducer }) => {
  return {
    userProfile: userProfileReducer.userProfile,
    verifyEmailData: MerchantListReducer.verifyEmailData,
    verifyOtpData: MerchantListReducer.verifyOtpData,
    changePswdData: MerchantListReducer.changePswdData
  }
}

const mapDispatchToProps = {
  verifyEmail: MerchantActions.verifyEmail,
  verifyOtp: MerchantActions.verifyOtp,
  changPassword: MerchantActions.changPassword,
  getUserProfile: UserProfileActions.getUserProfile,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingScreen));
