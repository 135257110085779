import t from "../actions/InvoicesActions/types";
import moment from "moment";
import DateFormatter from '../services/DateFormatter';

const initialState = {
    invoiceList: {},
    createData: {},
    netReceiable: [],
    netPayment: [],
    dateWiseInvoices: {},
    invoiceListUnderReview: {},
    pendingRequests: {},
    paymentDetails: [],
    invoice: {},
    createRequestEcommerce: {},
    typeDescription: '',
    markInvoiceVerify: null,
    verifyInvoiceOtp: null,
    markInvoiceReject: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_MERCHANT_BUYER_INVOICES:
            if (action.payload.body) {
                var finterData = action.payload.body.filter((element) => {
                    return element.type == "Normal" && !element.tradRequestId;
                })
            }

            return {
                ...state,
                invoiceList: finterData
            };
        case t.CREATE_INVOICE:
            return {
                ...state,
                createData: action.payload.body
            }
        case t.GET_INVOICES:
            //console.log("GET_INVOICES", action.payload);
            // let underReview = action.payload.body.filter((element) => {
            //     return element.status == "Under Review"
            // })
            return {
                ...state,
                invoiceListUnderReview: action.payload.body,
                typeDescription: action.payload.body ? action.payload.body.buyerPlatforms.typeDescription : ''

            };
        case t.GET_PENDING_REQUESTS:
            //console.log("GET_INVOICES", action.payload);
            // let underReview = action.payload.body.filter((element) => {
            //     return element.status == "Under Review"
            // })
            return {
                ...state,
                pendingRequests: action.payload.body
            };
        case t.GET_ALL_INVOICES:
            // Net Receviable

            //console.log("action.payload.body.Invoices", action.payload.body);

            const filterInvoices = action.payload.body ? action.payload.body.Invoices.filter((element) => {
                return element.type == "Normal" && element.invoiceStatus != "Under Review" &&
                    moment(new Date(element.dueDate)).isAfter(new Date(), 'd')
                    && (element.actualPaidAmount == undefined || element.actualPaidAmount == 0);
            }) : []
            //Net Payments
            const df = new DateFormatter();
            let tmpData = {};
            filterInvoices.forEach((element) => {
                (tmpData[df.dateformatter(element.dueDate)] = tmpData[df.dateformatter(element.dueDate)] || []).push(element);
            });

            //console.log("tmpData", tmpData);

            let tmpDates = Object.keys(tmpData);
            tmpDates.sort(function (a, b) {
                return moment(a, 'DD MMM').format('X') - moment(b, 'DD MMM').format('X');
            })

            return {
                ...state,
                netReceiable: filterInvoices,
                netPayment: tmpDates,
                dateWiseInvoices: tmpData,
            };

        case t.GET_PAYMENT_DETAILS:
            console.log('action.payload.body.payments ', action.payload.body);
            return {
                ...state,
                paymentDetails: action.payload.body.payments
            }

        case t.GET_INVOICE_DETAILS:
            //console.log('action.payload.body ', action.payload.body);
            return {
                ...state,
                invoice: action.payload.body.Invoices
            }
        case t.GET_CREATEREQUESTECOMMERCE:
            //console.log('action.payload.body ', action.payload.body);
            return {
                ...state,
                createRequestEcommerce: action.payload.body
            }
        case t.MARK_INVOICE_VERIFY:
            return {
                ...state,
                markInvoiceVerify: action.payload
            }
        case t.MARK_INVOICE_REJECT:
            return {
                ...state,
                markInvoiceReject: action.payload
            }
        case t.VERIFY_INVOICE_OTP:
            return {
                ...state,
                verifyInvoiceOtp: action.payload
            }
        default:
            return state;
    }
};
