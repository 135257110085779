import t from '../actions/UserProfileActions/types';

const initialState = {
    userProfile: {},
    userDocument: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.GET_USER_DATA:
            return {
                ...state,
                userProfile: action.payload
            }
        case t.GET_USER_DOCUMENT:
            return {
                ...state,
                userDocument: action.payload
            }
        default:
            return state;
    }
};

