import t from "./types";
import { Api } from '../../services/httpService';

export const getMerchantBuyerInvoices = (merchantId, buyerPlatformId) => async (dispatch) => {
    const res = await Api('get', `/invoices/unpaid/${merchantId}/${buyerPlatformId}`);
    //console.log("getMerchantBuyerInvoices ", res);
    if (res) {
        await dispatch({ type: t.FETCHED_MERCHANT_BUYER_INVOICES, payload: res });
    }
}

export const createInvoice = (params) => async (dispatch) => {
    const res = await Api('post', `/invoices/entry`, params);
    if (res) {
        await dispatch({ type: t.CREATE_INVOICE, payload: res });
    }
}


export const createInvoiceManually = (params) => async (dispatch) => {
    const res = await Api('post', `/invoices/entry`, params);
    if (res.success === true && params['invoicePDFFile']) {
        let form_data = new FormData();
        form_data.append('file', params.invoicePDFFile, params.invoicePDFFile.name)
        form_data.append('invoicesId', res.body.id)
        form_data.append('buyerPlatformsId', params.buyerPlatformId)
        form_data.append("merchantId", params.merchantId);
        dispatch(invoicePdf(form_data));
    }
    if (res) {
        await dispatch({ type: t.CREATE_INVOICE, payload: res });
    }
}


export const invoicePdf = (params) => async (dispatch) => {
    for (var key of params.entries()) {
        // console.log('entries2', key[0] + ', ' + key[1]);
    }
    const res = await Api('post', `/invoices/pdf`, params, true);
    // if (res) {
    //     await dispatch({ type: t.INVOICE_PDF, payload: res });
    // }
};


export const getAllTraditionalrequests = (merchantId, buyerPlatformId) => async (dispatch) => {

    const res = await Api('get', `/traditionalrequests/${merchantId}/${buyerPlatformId}`);
    if (res) {
        await dispatch({ type: t.GET_INVOICES, payload: res });
    }
}

export const getAllInvoicesData = (merchantId, buyerPlatformId) => async (dispatch) => {
    //console.log("MerchantId", merchantId);
    //console.log("buyerPlatformId", buyerPlatformId);
    const res = await Api('get', `/invoices/${merchantId}/${buyerPlatformId}`);
    if (res) {
        await dispatch({ type: t.GET_ALL_INVOICES, payload: res });
    }
}



export const getPendingRequests = (merchantId, buyerPlatformId) => async (dispatch) => {
    const res = await Api('get', `/pending/${merchantId}/${buyerPlatformId}`);
    if (res) {
        await dispatch({ type: t.GET_PENDING_REQUESTS, payload: res });
    }
}


export const getPaymentDetails = (userId) => async (dispatch) => {
    const res = await Api('get', `/payments/${userId}`);
    console.log("getPaymentDetails::::::", res);
    if (res) {
        await dispatch({ type: t.GET_PAYMENT_DETAILS, payload: res });
    }
}

export const getSingleInvoice = (invoiceId) => async (dispatch) => {
    const res = await Api('get', `/invoices/${invoiceId}`);
    if (res) {
        await dispatch({ type: t.GET_INVOICE_DETAILS, payload: res });
    }
}

export const getCreateRequestEcommerceData = (merchantId, buyerPlatformId) => async (dispatch) => {
    const res = await Api('get', `/factparam/${merchantId}/${buyerPlatformId}`);
    // console.log("getCreateRequestEcommerceData",res);
    if (res) {
        await dispatch({ type: t.GET_CREATEREQUESTECOMMERCE, payload: res });
    }
}


export const verifyInvoiceOtp = (param) => async (dispatch) => {
    const res = await Api('post', `/invoices/validateInvoiceOTP?invUUID=${param['invUUID']}&OTP=${param.OTP}`);
    console.log(res);
    if (res) {
        await dispatch({ type: t.VERIFY_INVOICE_OTP, payload: res });
    }
}

export const markInvoiceVerify = (param) => async (dispatch) => {
    const res = await Api('post', `/invoices/markInvoiceVerify?invUUID=${param['invUUID']}&OTP=${param.OTP}`, param)
    if (res) {
        await dispatch({ type: t.MARK_INVOICE_VERIFY, payload: res });
    }
}
export const markInvoiceReject = (param) => async (dispatch) => {
    const res = await Api('post', `/invoices/markInvoiceReject?invUUID=${param['invUUID']}&OTP=${param.OTP}&rejectReason=${param.rejectReason}`, param)
    if (res) {
        await dispatch({ type: t.MARK_INVOICE_REJECT, payload: res });
    }
}


