import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { InvoicesActions, MerchantActions } from "../../../actions";
import moment from "moment";
import NumberFormat from 'react-number-format';
import { url } from '../../../constants';
import Spinner from "react-bootstrap/Spinner";
import './reserveModal.css'
import axios from "axios";
import _, { isEqual } from 'lodash';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'



// import Chart from 'react-apexcharts';

class ReserveModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            amountError: '',
            widthDrawAmount: '',
            Loading: true,
            listDataSource: [],
            inputAmount: '',
            isButtonDisabled: true,
            reserveAmount: 0,
            loadingOnButton: false,
            merchantId: '',
            buyerPlatformId: "",
            viewDocumentLoading: false

        }
    }
    componentDidMount() {
        if (this.props.dashboardData) {
            this.setState({ reserveAmount: this.props.dashboardData.dashboard.reserveAmount, merchantId: this.props.merchantId, buyerPlatformId: this.props.buyerPlatformId })
        }
        this.fetchWalletData(this.props.merchantId, this.props.buyerPlatformId)
        // this.props.socket.on('walletAdded', () => {
        //     if (this.props.dashboardData) {
        //         this.setState({ reserveAmount: this.props.dashboardData, merchantId: this.props.merchantId, buyerPlatformId: this.props.buyerPlatformId })
        //     }
        //     this.fetchWalletData(this.props.merchantId, this.props.buyerPlatformId)
        // })
        // this.props.socket.on('walletUpdated', () => {
        //     if (this.props.dashboardData) {
        //         this.setState({ reserveAmount: this.props.dashboardData, merchantId: this.props.merchantId, buyerPlatformId: this.props.buyerPlatformId })
        //     }
        //     this.fetchWalletData(this.props.merchantId, this.props.buyerPlatformId)
        // })
    }

    fetchWalletData = (userId, bpID) => {
        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        axios({
            method: "get",
            url: url + "/wallet/" + userId + "/" + bpID,
            headers: { "Authorization": tokenHeader },
        }).then((res) => {
            if (res.status == 200) {
                this.setState({ Loading: false })
                if (res.data.body) {
                    const array3 = [].concat(res.data.body.wallet);
                    let sortedData = array3.sort((a, b) => {
                        return b.transactionDate - a.transactionDate;
                    });
                    this.setState({ listDataSource: sortedData })
                };
            } else {
                this.setState({ Loading: false })
            }
        }).catch(err => {
            this.setState({ Loading: false });
            console.log(err);
        });
        // 
    };
    addWalletRequest = () => {
        this.setState({ Loading: true });
        let params = {
            "transactionType": "DR",
            "transactionRemarks": "",
            "amount": this.state.inputAmount,
            "currency": "AED",
            "status": "Requested",
            "transactionDate": new Date(),
            "merchantBuyerPlatformsId": {
                "merchantId": this.state.merchantId,
                "buyerPlatformsId": this.state.buyerPlatformId
            }
        }
        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        axios({
            method: "post",
            url: url + "/wallet/entry/",
            data: params,
            headers: { "Authorization": tokenHeader },
        }).then((res) => {
            if (res.status == 200) {
                this.fetchWalletData(this.state.merchantId, this.state.buyerPlatformId);
                this.setState({ reserveAmount: this.state.reserveAmount, inputAmount: '' });

            }
        }).catch(err => {
            console.log(err);
            this.setState({ Loading: false });

        });

    }
    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.inputAmount, this.state.inputAmount)) {
            if (this.state.inputAmount == '' || this.state.inputAmount == 0) {
                this.setState({
                    amountError: '',
                    isButtonDisabled: true
                })
            } else if (this.state.inputAmount > this.state.reserveAmount) {
                this.setState({
                    amountError: 'Entered amount can not be greater than Reserve Balance.',
                    isButtonDisabled: true
                })
            } else {
                this.setState({
                    amountError: '',
                    isButtonDisabled: false
                })
            }
        }
    };
    onChangeFactAmtHandler = (e) => {
        this.setState({ ...this.state, inputAmount: e.target.value })
    };
    statusColorValidation = (status) => {
        if (status == 'Requested') {
            return { color: "#f0844a" }
        }
        else if (status == 'Rejected') {
            return { color: "#e61410" }
        }
        else {
            return { color: "#169E9A" }
        }
    }
    getInvoicePdf = (invoicePdf) => {
        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        var requestOptions = {
            method: "GET",
            headers: { "Authorization": tokenHeader },
        };
        this.setState({ viewDocumentLoading: true });
        fetch(`${url}/wallet/getFile/${invoicePdf}`, requestOptions)
            .then((response) => response.blob())
            .then((result) => {
                var fileURL = URL.createObjectURL(result);
                window.open(fileURL, "_blank");
                this.setState({ viewDocumentLoading: false });

            })

            .catch((error) => { console.log("error", error); this.setState({ viewDocumentLoading: false }); });

    }

    render() {
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal(false)}
                dialogClassName="modalRight"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0" closeButton>Withdraw Reserve</Modal.Header>
                <Modal.Body>
                    {this.state.Loading || this.state.viewDocumentLoading
                        ? <div className="loadersheet">

                            <Spinner animation="border" />

                        </div >
                        :
                        <div>
                            <div className="row mr-5">
                                <p className="balancelabel ml-2">Balance: AED {this.state.reserveAmount}</p>
                                <div className="col-sm-12 mb-4">
                                    <div className="row m-0">
                                        <p className="CR_label">AED</p>
                                    </div>
                                    <div className="row ml-0">
                                        <input type="text" value={this.state.inputAmount} className=" form-control CR_selectInvoice" onChange={this.onChangeFactAmtHandler} placeholder="x x x x x x" />
                                        {!this.state.amountError && !this.state.isButtonDisabled
                                            ? <a href="javascript:void(0)" className="done_btn efunder-btn cursor"
                                                onClick={this.addWalletRequest}>Withdraw</a>
                                            : <a href="javascript:void(0)" className="done_btn efunder-btn" disabled>Withdraw</a>}
                                        {this.state.amountError ? <span className='w-100 mt-1 ml-1' style={{ color: "red" }}>{this.state.amountError}</span> : ''}
                                    </div>
                                </div>
                                <div>

                                </div>

                            </div>
                            <p className="CR_label">History</p>
                            <table class="table">
                                <tbody>
                                    {this.state.listDataSource.length > 0 && this.state.listDataSource.map((transaction, index) => (
                                        <tr>
                                            <td>
                                                <p style={{ color: "#169E9A" }}>{transaction.transactionDate ? moment.unix(transaction.transactionDate / 1000).format('MMM-DD-YY HH:mm') : ""}</p>
                                                <p style={{ color: "grey" }}>Type</p>
                                                <Accordion className="notification-wrap cursor">
                                                    <Card className="mb-0">
                                                        <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                                                            <h3 className="accordion-title" style={{ color: "#169E9A" }}>{transaction.transactionType == 'CR' ? 'Credit' : 'Debit'}<FontAwesomeIcon icon={faAngleDown} /></h3><br />
                                                            <Accordion.Collapse eventKey={index + 1}>
                                                                <Card.Body>
                                                                    <p>{transaction.transactionRemarks}</p>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Accordion.Toggle>
                                                    </Card>
                                                </Accordion>
                                            </td>
                                            <td>
                                                <p style={{ color: "grey", marginTop: 30 }}>Amount(AED)</p>
                                                <h3 style={{ color: "#169E9A" }}>{transaction.amount ? <NumberFormat value={transaction.amount.toFixed(2)} displayType={'text'} thousandSeparator={true} /> : "0.00"}</h3>
                                            </td>
                                            <td>
                                                <p className="paid-label" style={this.statusColorValidation(transaction.status)}>{transaction.status}</p>
                                                <p style={{ color: "grey" }}>Balance(AED)</p>
                                                <h3 style={{ color: "#169E9A" }}>{transaction.balance ? <NumberFormat value={transaction.balance.toFixed(2)} displayType={'text'} thousandSeparator={true} /> : "0.00"}</h3>
                                                {transaction.receiptFileId
                                                    ? <a className="cursor" style={{ color: "#000000", }} onClick={() => this.getInvoicePdf(transaction.receiptFileId)}>View Receipt</a>
                                                    : ''}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = ({ InvoiceReducer, MerchantListReducer }) => {
    return {
        netReceiable: InvoiceReducer.netReceiable,
        dateWiseInvoices: InvoiceReducer.dateWiseInvoices,
        netPaymentDate: InvoiceReducer.netPayment,
        dashboardData: MerchantListReducer.dashboardData,
    }
}

const mapDispatchToProps = {
    getAllInvoicesData: InvoicesActions.getAllInvoicesData,
    getDashboardData: MerchantActions.getDashboardData,

}
export default connect(mapStateToProps, mapDispatchToProps)(ReserveModal)


