import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { InvoicesActions } from "../../../actions";
import { url } from "../../../constants";
import "./styles.css"
import ContentLoader from "react-content-loader";
import isEqual from 'lodash/isEqual'

class NetPayments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            logoIcon : {},
            active: [],
            selectedBuyerPlatform : [],
            buyerPlatformImageLoading: true,
            orActives: false,
            modalActives : [],
            selectedValue : {}
        }        
    }

    componentDidMount(){
      let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
      this.props.linkedWith.map((item, index) => {
        let buyerID = item.id;
        
        if (item.logo != null) {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: { "Authorization": tokenHeader },
          };
          let self = this;
          fetch(`${url}/files/${item.logo.id}`, requestOptions)
            .then((response) => response.blob())
            .then((result) => {
              var reader = new FileReader();
              reader.readAsDataURL(result);
              reader.onloadend = function () {
                var base64data = reader.result;
                let temp = { ...self.state.logoIcon };
                temp[buyerID] = base64data;
                self.setState({ ...self.state, logoIcon: temp });
                console.log("state deo", self.state.logoIcon);
              };
            })
            .catch((error) => console.log("error", error));
        }
      });
        let ID = this.props.linked[0].id;
        if (this.props.linked[0].logo != null) {
          var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: { "Authorization": tokenHeader },
          };
          let self = this;
          fetch(`${url}/files/${this.props.linked[0].logo.id}`, requestOptions)
            .then((response) => response.blob())
            .then((result) => {
              console.log("result of logo in buyerP", result);
              var reader = new FileReader();
              reader.readAsDataURL(result);
              reader.onloadend = function () {
                var base64data = reader.result;
                let temp = { ...self.state.logoIcon };
                temp[ID] = base64data;
                self.setState({ ...self.state, logoIcon: temp });
                console.log("state deo", self.state.logoIcon);
              };
            })
            .catch((error) => console.log("error", error));
        }
        let blankArray = [...this.props.linkedWith,...this.props.linked]
        this.setState({
          modalActives : blankArray
        })
        

    }

    componentDidUpdate(prevProps,prevState){
      if(!isEqual(prevProps.active,this.props.active)){
        this.setState({
          active : this.props.active
        })
      }
    }

    MyLoader = () => (
        <ContentLoader>
          <rect x="4" y="-18" rx="5" ry="5" width="110" height="110" />
        </ContentLoader>
      );
  render() {
        return (
            <Modal
                show={this.props.openModal}
                dialogClassName="modalRight platform-popup"
                aria-labelledby="example-custom-modal-styling-title" centered>
                <Modal.Header className="border-0 text-theme-color">Choose financing option</Modal.Header>
                <Modal.Body className="align-center">
          <div className="row justify-content-center" >
            {this.props.linkedWith.map((val, index) => (
              <div className="col-sm-4"
                style={index == 0 || index == 1 /* || index == 2 || index == 3 */ ?
                  { padding: '0px 15px', marginTop: 16, width: 180, textAlign: 'center' } :
                  { padding: '0px 15px', marginTop: 16, width: 180, textAlign: 'center' }} key={val.id}>
                {/* <p>{console.log("bal", val)}</p> */}
                <div className="linked-wrap">
                <input className="linked-radio cursor" type="radio" name="test" value="one" onClick={() => {
                  this.props.buttonPress(val.id,null,this.props.active,this.state.modalActives)
                  this.setState({
                    selectedValue : val
                  })
                }} />
                <button
                    className={
                      this.props.active[val.id]
                        ? "imgButtonActive"
                        : "imgButton"
                    }
                    style={{ /* backgroundColor: "red"  */ }}
                  >
                    {this.state.logoIcon[val.id] === null
                      ?  (<p style={{ fontSize: 45 }}>NA</p>)
                      : (<img
                        src={this.state.logoIcon[val.id]}
                        style={{ width: 72, height: 72, marginTop: 0/* , backgroundColor: "red" */ }} />)}
                  </button>
                  <p
                    className={
                      this.props.active[val.id]
                        ? "buttonLabelActive"
                        : "buttonLabel"
                    }
                    style={val.name.length > 5 ? { marginTop: 16 } : { marginTop: 16 }}
                  >
                    {val.name} <br />
                    (Working Capital)
                  </p>
                </div>
              </div>
            ))}
            <div className="col-sm-4"
                key={this.props.linked[0].id}>
                {/* <p>{console.log("bal", val)}</p> */}
                <div className="linked-wrap">
                <input className="linked-radio cursor" type="radio" name="test" value="two" onClick={() => {
                  this.props.buttonPress(this.props.linked[0].id,null,this.props.active,this.state.modalActives)
                  this.setState({
                    selectedValue : this.props.linked[0]
                  })
                  }} />
                <button
                  className={
                    this.props.active[this.props.linked[0].id]
                      ? "imgButtonActive"
                      : "imgButton"
                  }
                  style={{ /* backgroundColor: "red"  */ marginTop: '15px' }}
                >
                  {this.state.logoIcon[this.props.linked[0].id] === null
                    ?  (<p style={{ fontSize: 45 }}>NA</p>)
                    : (<img
                      src={this.state.logoIcon[this.props.linked[0].id]}
                      style={{ width: 72, height: 72, marginTop: 0/* , backgroundColor: "red" */ }} />)}
                </button>
                <p
                  className={
                    this.props.active[this.props.linked[0].id]
                      ? "buttonLabelActive"
                      : "buttonLabel"
                  }
                  style={{ marginTop: 16 }}
                >
                      {this.props.linked[0].name}<br />
                      (Market Place)
                </p>
                </div>
              </div>
              </div>
              <div>
                {/* <button onHide={() => this.props.closeModal(this.props.active)}>Done</button> */}
                </div>
                <button className="CreateAccformSelectBP cursor" type="button"
                  onClick={() => this.props.closeModal(this.props.active,this.state.selectedValue,this.state.modalActives)}>
                  Done 
                </button>
            </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = ({ InvoiceReducer }) => {
    return {
        netReceiable: InvoiceReducer.netReceiable,
        dateWiseInvoices: InvoiceReducer.dateWiseInvoices,
        netPaymentDate: InvoiceReducer.netPayment
    }
}

const mapDispatchToProps = {
    getAllInvoicesData: InvoicesActions.getAllInvoicesData
}
export default connect(mapStateToProps, mapDispatchToProps)(NetPayments)


