import React, { useEffect, useState } from 'react';
import './Sidebar.css';
import { Link, useHistory } from 'react-router-dom';
import CmsModal from '../../components/Modals/CmsModal/CmsModal';

const SideBar = () => {
    let history = useHistory();
    const [openModal, setOpenModel] = useState(false);
    const [callModelFor, setCallModelFor] = useState("");

    // let {id} = useParams();
    let id = null;
    const [currentIndex, setCurrentIndex] = useState(0);

    //inactive image
    const home = require('../../assests/Dashboard.png');
    const req = require('../../assests/Requests.png');
    const pay = require('../../assests/Payments.png');


    //active image
    const A_home = require('../../assests/home.png');
    const A_req = require('../../assests/req.png');
    const A_pay = require('../../assests/pay.png');


    useEffect(() => {
        let currLoca = (window.location.pathname).split('/');
        if (window.location.pathname == '/user' || window.location.pathname == '/'
            || window.location.pathname == '') {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(-1)
        }
    }, []);

    const isActiveItems = (index) => {
        setCurrentIndex(index)
    }

    const gotoHomePage = () => {
        history.push(`/`)
    }


    const showModal = (Data) => {
        setCallModelFor(Data);
        setOpenModel(true);
    }

    const closeModal = () => {
        setOpenModel(false);
    }

    return (
        <div className="row" >
            <div className="col-sm-3">
                <div className="sidebar">
                    <ul >
                        <div
                            className="sidebar_item_container"
                            style={{ backgroundColor: currentIndex == 0 || window.location.pathname === '/user/createRequest' ? "white" : " var(--color-2f2)", top: 70, cursor: "pointer" }}
                            onClick={() => isActiveItems(0)} onClick={() => gotoHomePage()}>
                            <div className="side-rect" style={{ backgroundColor: currentIndex == 0 || window.location.pathname === '/user/createRequest' ? "#169E9A" : " var(--color-2f2)" }}></div>
                            <div className="icon" style={{ top: 16 }}>
                                <img src={currentIndex == 0 || window.location.pathname === '/user/createRequest' ? A_home : home} width={32} height={32} />
                            </div>
                            <p className={currentIndex == 0 || window.location.pathname === '/user/createRequest' ? "active_text" : "normal_text"} style={{ top: 25 }}>Dashboard</p>
                        </div>

                        {/* <Link to={`/user/requests/${id}`}> */}
                        {/* </Link> */}
                        {/* <Link to={`/user/payments/${id}`}> */}
                        {/* </Link>       */}

                    </ul>
                    <p className="footer" onClick={() => showModal("Privacy Policy")}>
                        <button className="mr-3">Privacy Policy</button>
                    </p>

                </div>

            </div>
           
                <CmsModal
                    openModal={openModal}
                    closeModal={() => closeModal()}
                    modalTitle={callModelFor}
                />
            
        </div>
    )

}

export default SideBar;