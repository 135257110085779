import { combineReducers } from "redux";
import isLoggedReducer from "./isLoggedReducer";
import merchantListReducer from "./merchantListReducer";
import InvoiceReducer from "./InvoiceReducer";
import userProfileReducer from "./userProfileReducer";

const rootReducers = combineReducers({
  isLogged: isLoggedReducer,
  MerchantListReducer: merchantListReducer,
  InvoiceReducer: InvoiceReducer,
  userProfileReducer: userProfileReducer
});
export default rootReducers;
