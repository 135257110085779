import React, { Component } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import BottomScrollListener from "react-bottom-scroll-listener";
import "./styles.css";
import { TermsnConditionsRules } from "./TermsnConditionsRules";
import { url } from "../../constants";
import { connect } from "react-redux";
import { MerchantActions } from '../../actions'
class TermsScreen extends Component {
  constructor(props) {
    super();
    //this.scrollRef = React.createRef();
  }

  state = {
    isDisabled: true,
    isChecked: false,
    submitted: false,
    dataToShow: {}
  };

  handleCheckboxChange = (event) => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    //console.log(this.props.id);
    //console.log(this.props.name);
    //console.log(this.props.email);
    //console.log(this.props.phone);
    //console.log(this.props.pass);

    var dataRaw = {
      id: this.props.id,
      name: this.props.name,
      email: this.props.email,
      password: this.props.pass,
      phone: this.props.phone,
      status: "ACTIVE",
    };
    let tokenHeader = "";
    if (localStorage.getItem("_token")) {
      tokenHeader = localStorage.getItem("_token");
    }
    axios({
      method: "post",
      url: url + "/merchant/",
      data: dataRaw,
      headers: { "Authorization": tokenHeader }

    })
      .then(function (response) {
        //console.log(response);
        //console.log(response.status);
        const s = response.status;
        //console.log(s);
        const n = 200;
        //console.log(n);
        if (s === n) {
          //console.log(response.data.body);
          // window.location = "/user/home/" + response.data.body.id;

          if (response.data.body.buyerPlatforms == null) {
            window.location =
              "/user/SelectBuyerPlatform";
          } else {
            window.location = "/user";
          }
        } else alert("Invalid Credentials");
      })
      .catch(function (error) {
        console.log(error);
        alert("Invalid things");
      });
  };

  handleScroll = (e) => {
    const paddingToBottom = 20;
    const bottom =
      e.target.clientHeight + e.target.scrollTop >=
      e.target.scrollHeight - paddingToBottom;
    // e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    //console.log("scrollHeight", e.target.scrollHeight);
    //console.log("scrollTop", e.target.scrollTop);
    //console.log("clientHeight", e.target.clientHeight);
    // //console.log("clientHeight", 0.8 * e.target.clientHeight);
    if (bottom) {
      //console.log("Bottom Reached");
      this.setState({
        isDisabled: false,
      });
    }
  };

  componentDidMount() {
    this.props.getCmsPage(1).then(() => {
      this.setState({
        dataToShow: this.props.getCmsPageSingle
      })
    })
  }

  render() {

    //const logo = require("../../assests/Funder.ai.png");
    console.log('this.state.dataToShow', this.state.dataToShow)
    return (
      <div className="_form">
        <label className="welcome">Terms & Conditions</label>
        <p className="CaPara" style={{ marginBottom: 20 }}>
          Please read them thoroughly before proceeding further
        </p>

        <div className="TNCbox">
          <div className="containerTnc" onScroll={this.handleScroll}>
            <p dangerouslySetInnerHTML={{ __html: this.state.dataToShow.description }} />
          </div>
        </div>

        <div className="row TncCheckbox py-4">
          <input type="checkbox" id="checkbox-2-1"
            className={"regular-checkbox1 "}
            onChange={this.handleCheckboxChange}
            checked={this.state.isChecked}
            disabled={this.state.isDisabled}
            style={{ height: 21, width: 21, borderRadius: 8 }} />
          <p className="checkbox_text m-0"
            style={this.state.isDisabled ? { color: 'var(--border-9d8)' } : { color: 'var(--color-737)' }}> &nbsp;
            I have read & agree to the Terms & Conditions.
          </p>
        </div>

        <button
          className="CreateAccformLoginDisabled2 w-100 cursor"
          type="submit"
          disabled={!this.state.isChecked}
          onClick={this.handleSubmit}
          style={this.state.isChecked ? { backgroundColor: "#169e9a" } : { backgroundColor: "var(--color-ece)" }}
        >
          Create Account
        </button>
      </div>

    );
  }
}
const mapStateToProps = ({ MerchantListReducer }) => {
  return {
    getCmsPageSingle: MerchantListReducer.cmsPageSingle,
    cmsPage: MerchantListReducer.cmsPage
  }

}

const mapDispatchToProps = {
  getCmsPage: MerchantActions.getCmsPage,
  getCmsPages: MerchantActions.getCmsPages
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsScreen)
