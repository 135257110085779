import { url } from "../constants";

export const Api = async (reqType, apiRouteUrl, body, isFormData = false) => {
    try {

        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }
        if (isFormData === true) {  //formdata object
            const requestOptions = {
                method: reqType,
                headers: { "Authorization": tokenHeader },
                body: body
            };

            let APIURL = url + apiRouteUrl;
            let response = await fetch(APIURL, requestOptions);
            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = [];
            }
            return data;
        } else {

            const requestOptions = {
                method: reqType,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": tokenHeader
                },
                body: JSON.stringify(body)
            };
            let response = await fetch(url + apiRouteUrl, requestOptions);
            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = [];
            }
            return data;
        }
    } catch (err) {
        if (err.code && err.code === 403) {
            throw { code: 403 }; // eslint-disable-line
        } else {
            let error = err.toJSON();
            if (error.message === "Network Error") {
                throw { code: 403 }; // eslint-disable-line
            }
        }
    }
}