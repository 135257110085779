import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducers from "../reducers";
import history from '../history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = createStore(
  connectRouter(history)(rootReducers),
  composeWithDevTools(
    applyMiddleware(reduxThunk),
    applyMiddleware(routerMiddleware(history)),
  )
);
