import React, { useEffect, useState, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { MerchantActions } from '../../../actions';
const CmsModal = (props) => {
    const [cmsData, setCmsData] = useState("");
    const [downloadTxt, setDownloadTxt] = useState("");

    useEffect(() => {
        setCmsData("");
        if (props.modalTitle == "Terms & Conditions") {
            props.getCmsPage(2);
            setDownloadTxt("Download T&C")
        }
        if (props.modalTitle == "Disclaimer") {
            props.getCmsPage(3);
            setDownloadTxt("Download Disclaimer")
        }
        if (props.modalTitle == "Privacy Policy") {
            props.getCmsPage(4);
            setDownloadTxt("Download Privacy Policy")
        }
    }, [props.modalTitle])




    useEffect(() => {
        setCmsData(props.getCmsPageSingle ? props.getCmsPageSingle.description : "")
    }, [props.getCmsPageSingle])

    const generatePdf = () => {
        window.print();
    }


    return (
        <div>
            <Modal
                className="ModalDemo"
                show={props.openModal}
                onHide={() => props.closeModal()}
                dialogClassName="mdlDir"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0 alignClose" closeButton>{props.modalTitle}
                    <span className="cursor clickevent" onClick={() => generatePdf()}>{downloadTxt}</span>
                </Modal.Header>
                <Modal.Body className="pb-100">
                    <p dangerouslySetInnerHTML={{ __html: cmsData ? cmsData : "" }} />
                </Modal.Body>
            </Modal>
            <p style={{ display: "none" }} id="section-to-print" dangerouslySetInnerHTML={{ __html: cmsData }} />
        </div>
    )
}

const mapStateToProps = ({ MerchantListReducer }) => {
    return {
        getCmsPageSingle: MerchantListReducer.cmsPageSingle,
    }
}
const mapDispatchToProps = {
    getCmsPage: MerchantActions.getCmsPage,
}
export default connect(mapStateToProps, mapDispatchToProps)(CmsModal);